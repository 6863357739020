import styles from "./Calendar.module.css";
import Container from "../../ui/container/Container";
import Wrap from "../../ui/wrap/Wrap";
import React, {useContext} from "react";
import {appContext} from "../../context/app/appContext";
import empty from '../../images/speaker-empty.png';
import cal1 from '../../images/btn1.svg';
import cal2 from '../../images/btn2.svg';
import cal3 from '../../images/btn3.svg';

export const Calendar = () => {
    const {appState, get_config_value, open_calendar} = useContext(appContext)
    const isMobile = window.innerWidth < 992 ? true : false
    let qa_link = get_config_value(appState.options, 'qa_link')

    if (isMobile) {
        return (
            <section className={styles.mobile_calendar} id="Calendar">
                <Container>
                    <Wrap>
                        <div className={styles.mobile_calendar__title}>
                            ПрограммаПрограмма 2021
                        </div>
                        <div className={styles.mobile_calendar__items}>
                            {
                                appState.calendar.map((d, index) => {
                                    let key = 'calendar' + index
                                    let type = d.type[0]
                                    let bgColor = '#F4F7FF';
                                    if (type === 'activity') {
                                        if (d.color && d.color.length > 0) {
                                            bgColor = d.color
                                        }
                                        if (d.isOpen !== undefined && d.isOpen === true) {
                                            return (
                                                <div key={key} className={styles.mobile_calendar__item}>
                                                    <div
                                                        className={styles.mobile_calendar__date}>
                                                        {
                                                            d.time
                                                        }
                                                    </div>
                                                    <div className={styles.mobile_calendar__text}>
                                                        <div className={styles.mobile_calendar__text_label}>
                                                            Активность
                                                        </div>
                                                        <div className={styles.mobile_calendar__text_heading}
                                                             style={{color: bgColor}}>
                                                            {
                                                                d.name
                                                            }
                                                        </div>
                                                        <div className={styles.mobile_calendar__text_label}>
                                                            Описание
                                                        </div>
                                                        <div
                                                            className={styles.mobile_calendar__text_description}>
                                                            {
                                                                d.description
                                                            }
                                                        </div>
                                                        <div
                                                            className={styles.mobile_calendar__text_calendars}>
                                                            {
                                                                d.materials !== undefined && (
                                                                    <a href={d.materials}
                                                                       target="_blank" rel="noreferrer">
                                                                        <img src={cal1} alt=""
                                                                             title=""/>
                                                                        Материалы
                                                                    </a>
                                                                )
                                                            }
                                                            {
                                                                d.recording !== undefined && (
                                                                    <a href={d.recording} target="_blank"
                                                                       rel="noreferrer">
                                                                        <img src={cal2} alt=""
                                                                             title=""/>
                                                                        Запись доклада
                                                                    </a>
                                                                )
                                                            }
                                                            {
                                                                d.presentation !== undefined && (
                                                                    <a href={d.presentation} target="_blank"
                                                                       rel="noreferrer">
                                                                        <img src={cal3} alt=""
                                                                             title=""/>
                                                                        Презентация (PDF)
                                                                    </a>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className={styles.mobile_calendar__close}
                                                         onClick={() => open_calendar(index)}>Закрыть
                                                    </div>

                                                </div>
                                            )
                                        } else {
                                            return (
                                                <div key={key} onClick={ () => open_calendar(index)}
                                                     className={styles.mobile_calendar__item}
                                                     style={{backgroundColor: bgColor}}>
                                                    <div
                                                        className={styles.mobile_calendar__date + ' ' + styles.mobile_calendar__white}>
                                                        {
                                                            d.time
                                                        }
                                                    </div>
                                                    <div
                                                        className={styles.mobile_calendar__name + ' ' + styles.mobile_calendar__white}>
                                                        {
                                                            d.name
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        }
                                    }
                                    if (type === 'report' || type === 'table' || type === 'qa') {
                                        if (d.isOpen !== undefined && d.isOpen === true) {
                                            let s = []
                                            let t = []
                                            if (d.speakers_images !== undefined && d.speakers_names !== undefined && d.speakers_companies !== undefined) {
                                                let i = d.speakers_images
                                                let n = d.speakers_names.split(/[\r\n]+/g)
                                                let c = d.speakers_companies.split(/[\r\n]+/g)
                                                if (i.length === n.length && n.length === c.length) {
                                                    for (let j = 0; j < i.length; j++) {
                                                        s[j] = {
                                                            image: i[j].url,
                                                            name: n[j],
                                                            company: c[j]
                                                        }
                                                    }
                                                }
                                            }
                                            if (d.tables_images !== undefined && d.tables_names !== undefined && d.tables_companies !== undefined) {
                                                let i2 = d.tables_images
                                                let n2 = d.tables_names.split(/[\r\n]+/g)
                                                let c2 = d.tables_companies.split(/[\r\n]+/g)
                                                if (i2.length === n2.length && n2.length === c2.length) {
                                                    for (let j2 = 0; j2 < i2.length; j2++) {
                                                        t[j2] = {
                                                            image: i2[j2].url,
                                                            name: n2[j2],
                                                            company: c2[j2]
                                                        }
                                                    }
                                                }
                                            }
                                            return (
                                                <div key={key} className={styles.mobile_calendar__item}>
                                                    <div
                                                        className={styles.mobile_calendar__date}>
                                                        {
                                                            d.time
                                                        }
                                                    </div>
                                                    <div className={styles.mobile_calendar__text}>
                                                        <div className={styles.mobile_calendar__text_label}>
                                                            {
                                                                type === 'qa' ? 'Активность' : 'Тема'
                                                            }
                                                        </div>
                                                        <div className={styles.mobile_calendar__text_heading}>
                                                            {
                                                                d.name
                                                            }
                                                        </div>
                                                        <div className={styles.mobile_calendar__text_label}>
                                                            {
                                                                type === 'qa' ? 'Описание' : 'План'
                                                            }
                                                        </div>
                                                        <div
                                                            className={styles.mobile_calendar__text_description}>
                                                            {
                                                                d.description
                                                            }
                                                        </div>
                                                        {
                                                            s.length > 0 && (
                                                                <div
                                                                    className={styles.mobile_calendar__spekears2}>
                                                                    <div
                                                                        className={styles.mobile_calendar__spekears2_label}>
                                                                        {
                                                                            type === 'report' && (s.length === 1 ? 'СПИКЕР' : 'СПИКЕРЫ')
                                                                        }
                                                                        {
                                                                            type === 'qa' && (s.length === 1 ? 'СПИКЕР' : 'СПИКЕРЫ')
                                                                        }
                                                                        {
                                                                            type === 'table' && (s.length === 1 ? 'МОДЕРАТОР' : 'МОДЕРАТОРЫ')
                                                                        }
                                                                    </div>
                                                                    <div
                                                                        className={styles.mobile_calendar__spekears2_list}>
                                                                        {
                                                                            s.map((d, index) => {
                                                                                return (
                                                                                    <div key={index}
                                                                                         className={styles.mobile_calendar__spekears2_item}>
                                                                                        <div
                                                                                            className={styles.mobile_calendar__spekears2_image}>
                                                                                            <img
                                                                                                src={d.image}
                                                                                                alt=""
                                                                                                title=""
                                                                                                loading="lazy"
                                                                                            />
                                                                                        </div>
                                                                                        <div
                                                                                            className={styles.mobile_calendar__spekears2_text}>
                                                                                            {
                                                                                                d.name
                                                                                            }
                                                                                            <span>
                                                                                            {
                                                                                                d.company
                                                                                            }
                                                                                        </span>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                        {

                                                            t.length > 0 && (
                                                                <div
                                                                    className={styles.mobile_calendar__spekears2}>
                                                                    <div
                                                                        className={styles.mobile_calendar__spekears2_label}>
                                                                        КРУГЛЫЙ СТОЛ
                                                                    </div>
                                                                    <div
                                                                        className={styles.mobile_calendar__spekears2_list}>
                                                                        {
                                                                            t.map((d, index) => {
                                                                                return (
                                                                                    <div key={index}
                                                                                         className={styles.mobile_calendar__spekears2_item}>
                                                                                        <div
                                                                                            className={styles.mobile_calendar__spekears2_image}>
                                                                                            <img
                                                                                                src={d.image}
                                                                                                alt=""
                                                                                                title=""
                                                                                                loading="lazy"
                                                                                            />
                                                                                        </div>
                                                                                        <div
                                                                                            className={styles.mobile_calendar__spekears2_text}>
                                                                                            {
                                                                                                d.name
                                                                                            }
                                                                                            <span>
                                                                                            {
                                                                                                d.company
                                                                                            }
                                                                                        </span>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                        {
                                                            type === 'qa' && (
                                                                <a href={qa_link} target="_blank"
                                                                   rel="noreferrer"
                                                                   className={styles.mobile_calendar__qa_link}>Задать
                                                                    вопрос</a>)
                                                        }
                                                        <div
                                                            className={styles.mobile_calendar__text_calendars}>
                                                            {
                                                                d.materials !== undefined && (
                                                                    <a href={d.materials}
                                                                       target="_blank" rel="noreferrer">
                                                                        <img src={cal1} alt=""
                                                                             title=""/>
                                                                        Материалы
                                                                    </a>
                                                                )
                                                            }
                                                            {
                                                                d.recording !== undefined && (
                                                                    <a href={d.recording} target="_blank"
                                                                       rel="noreferrer">
                                                                        <img src={cal2} alt=""
                                                                             title=""/>
                                                                        Запись доклада
                                                                    </a>
                                                                )
                                                            }
                                                            {
                                                                d.presentation !== undefined && (
                                                                    <a href={d.presentation} target="_blank"
                                                                       rel="noreferrer">
                                                                        <img src={cal3} alt=""
                                                                             title=""/>
                                                                        Презентация (PDF)
                                                                    </a>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className={styles.mobile_calendar__close}
                                                         onClick={() => open_calendar(index)}>Закрыть
                                                    </div>
                                                </div>
                                            )
                                        } else {
                                            if (d.speakers_image !== undefined) {
                                                return (
                                                    <div key={key} onClick={() => open_calendar(index)}
                                                         className={styles.mobile_calendar__item}>
                                                        <div className={styles.mobile_calendar__date}>
                                                            {
                                                                d.time
                                                            }
                                                        </div>
                                                        <div className={styles.mobile_calendar__speakers}>
                                                            <div
                                                                className={styles.mobile_calendar__speakers_img}>
                                                                <img src={d.speakers_image[0].url}
                                                                     alt="" title="" loading="lazy"/>
                                                            </div>
                                                            <div
                                                                className={styles.mobile_calendar__speakers_name}>
                                                                {
                                                                    d.speakers_names
                                                                }
                                                                <span>{d.speakers_company}</span>
                                                            </div>
                                                        </div>
                                                        <div className={styles.mobile_calendar__name}>
                                                            {
                                                                d.name
                                                            }
                                                        </div>
                                                        {
                                                            type === 'qa' && (
                                                                <a href={qa_link} target="_blank"
                                                                   rel="noreferrer"
                                                                   className={styles.mobile_calendar__qa_link}>Задать
                                                                    вопрос</a>)
                                                        }
                                                    </div>
                                                )
                                            }
                                        }
                                    }
                                    if (type === 'empty') {
                                        let bgColor = '#E7ECFE';
                                        return (
                                            <div key={key} className={styles.mobile_calendar__item}
                                                 style={{backgroundColor: bgColor}}>
                                                <div className={styles.mobile_calendar__date}>
                                                    {
                                                        d.time
                                                    }
                                                </div>
                                                <div className={styles.mobile_calendar__speakers}>
                                                    <div
                                                        className={styles.mobile_calendar__speakers_img}>
                                                        <img src={empty} alt="" title=""/>
                                                    </div>
                                                    <div className={styles.mobile_calendar__speakers_name}>
                                                        <span>Спикер вот-вот появится</span>
                                                    </div>
                                                </div>
                                                <div className={styles.mobile_calendar__name}>
                                                    {
                                                        d.name
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }
                                    return null
                                })
                            }
                        </div>
                    </Wrap>
                </Container>
            </section>
        )
    }
    return (
        <section className={styles.calendar} id="Calendar">
            <Container>
                <Wrap>
                    <div className={styles.calendar__title}>
                        Программа 2021
                    </div>
                    <div className={styles.calendar__table}>
                        {
                            appState.calendar.map((d, index) => {
                                let key = 'calendar' + index
                                let type = d.type[0]
                                let bgColor = '#F4F7FF';

                                if (type === 'activity') {

                                    if (d.color && d.color.length > 0) {
                                        bgColor = d.color
                                    }
                                    if (d.isOpen !== undefined && d.isOpen === true) {
                                        return (
                                            <div key={key}
                                                 className={styles.calendar__tr_open}>
                                                <div className={styles.calendar__time}>
                                                    {
                                                        d.time
                                                    }
                                                </div>
                                                <div className={styles.calendar__text}>
                                                    <div className={styles.calendar__text_label}>
                                                        Активность
                                                    </div>
                                                    <div className={styles.calendar__text_heading}
                                                         style={{color: bgColor}}>
                                                        {
                                                            d.name
                                                        }
                                                    </div>
                                                    <div className={styles.calendar__text_label}>
                                                        Описание
                                                    </div>
                                                    <div
                                                        className={styles.calendar__text_description}>
                                                        {
                                                            d.description
                                                        }
                                                    </div>
                                                    <div
                                                        className={styles.calendar__text_calendars}>
                                                        {
                                                            d.materials !== undefined && (
                                                                <a href={d.materials}
                                                                   target="_blank" rel="noreferrer">
                                                                    <img src={cal1} alt=""
                                                                         title=""/>
                                                                    Материалы
                                                                </a>
                                                            )
                                                        }
                                                        {
                                                            d.recording !== undefined && (
                                                                <a href={d.recording} target="_blank"
                                                                   rel="noreferrer">
                                                                    <img src={cal2} alt=""
                                                                         title=""/>
                                                                    Запись доклада
                                                                </a>
                                                            )
                                                        }
                                                        {
                                                            d.presentation !== undefined && (
                                                                <a href={d.presentation} target="_blank"
                                                                   rel="noreferrer">
                                                                    <img src={cal3} alt=""
                                                                         title=""/>
                                                                    Презентация (PDF)
                                                                </a>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                                <div className={styles.calendar__right}>
                                                    <div className={styles.calendar__close}
                                                         onClick={() => open_calendar(index)}>
                                                        Закрыть
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div key={key} className={styles.calendar__tr+' '+styles.calendar__tr_hover2}
                                                 style={{backgroundColor: bgColor}}
                                                 onClick={() => open_calendar(index)}>
                                                <div
                                                    className={styles.calendar__time + ' ' + styles.calendar__white}>
                                                    {
                                                        d.time
                                                    }
                                                </div>
                                                <div
                                                    className={styles.calendar__name + ' ' + styles.calendar__white + ' ' + styles.calendar_name_1}>
                                                    {
                                                        d.name
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }
                                }
                                if (type === 'report' || type === 'table' || type === 'qa') {
                                    if (d.isOpen !== undefined && d.isOpen === true) {
                                        let s = []
                                        let t = []
                                        if (d.speakers_images !== undefined && d.speakers_names !== undefined && d.speakers_companies !== undefined) {
                                            let i = d.speakers_images
                                            let n = d.speakers_names.split(/[\r\n]+/g)
                                            let c = d.speakers_companies.split(/[\r\n]+/g)
                                            if (i.length === n.length && n.length === c.length) {
                                                for (let j = 0; j < i.length; j++) {
                                                    s[j] = {
                                                        image: i[j].url,
                                                        name: n[j],
                                                        company: c[j]
                                                    }
                                                }
                                            }
                                        }
                                        if (d.tables_images !== undefined && d.tables_names !== undefined && d.tables_companies !== undefined) {
                                            let i2 = d.tables_images
                                            let n2 = d.tables_names.split(/[\r\n]+/g)
                                            let c2 = d.tables_companies.split(/[\r\n]+/g)
                                            if (i2.length === n2.length && n2.length === c2.length) {
                                                for (let j2 = 0; j2 < i2.length; j2++) {
                                                    t[j2] = {
                                                        image: i2[j2].url,
                                                        name: n2[j2],
                                                        company: c2[j2]
                                                    }
                                                }
                                            }
                                        }
                                        return (
                                            <div key={key}
                                                 className={styles.calendar__tr_open}>
                                                <div className={styles.calendar__time}>
                                                    {
                                                        d.time
                                                    }
                                                </div>
                                                <div className={styles.calendar__text}>
                                                    <div className={styles.calendar__text_label}>
                                                        {
                                                            type === 'qa' ? 'Активность' : 'Тема'
                                                        }

                                                    </div>
                                                    <div
                                                        className={ styles.calendar__text_heading}>
                                                        {
                                                            d.name
                                                        }
                                                    </div>
                                                    <div className={styles.calendar__text_label}>

                                                        {
                                                            type === 'qa' ? 'Описание' : 'План'
                                                        }
                                                    </div>
                                                    <div
                                                        className={styles.calendar__text_description}>
                                                        {
                                                            d.description
                                                        }
                                                    </div>
                                                    <div
                                                        className={styles.calendar__text_calendars}>
                                                        {
                                                            d.materials !== undefined && (
                                                                <a href={d.materials}
                                                                   target="_blank" rel="noreferrer">
                                                                    <img src={cal1} alt=""
                                                                         title=""/>
                                                                    Материалы
                                                                </a>
                                                            )
                                                        }
                                                        {
                                                            d.recording !== undefined && (
                                                                <a href={d.recording} target="_blank"
                                                                   rel="noreferrer">
                                                                    <img src={cal2} alt=""
                                                                         title=""/>
                                                                    Запись доклада
                                                                </a>
                                                            )
                                                        }
                                                        {
                                                            d.presentation !== undefined && (
                                                                <a href={d.presentation} target="_blank"
                                                                   rel="noreferrer">
                                                                    <img src={cal3} alt=""
                                                                         title=""/>
                                                                    Презентация (PDF)
                                                                </a>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                                <div className={styles.calendar__right}>
                                                    {
                                                        type === 'qa' && (
                                                            <a href={qa_link} target="_blank"
                                                               rel="noreferrer"
                                                               className={styles.calendar__qa_link}>Задать
                                                                вопрос</a>)
                                                    }
                                                    <div className={styles.calendar__close}
                                                         onClick={() => open_calendar(index)}>
                                                        Закрыть
                                                    </div>
                                                    {
                                                        s.length > 0 && (
                                                            <div
                                                                className={styles.calendar__spekears2 + ' ' + styles.calendar__spekears2_mt100}>
                                                                <div
                                                                    className={styles.calendar__spekears2_label}>
                                                                    {
                                                                        type === 'report' && (s.length === 1 ? 'СПИКЕР' : 'СПИКЕРЫ')
                                                                    }
                                                                    {
                                                                        type === 'qa' && (s.length === 1 ? 'СПИКЕР' : 'СПИКЕРЫ')
                                                                    }
                                                                    {
                                                                        type === 'table' && (s.length === 1 ? 'МОДЕРАТОР' : 'МОДЕРАТОРЫ')
                                                                    }
                                                                </div>
                                                                <div
                                                                    className={styles.calendar__spekears2_list}>
                                                                    {
                                                                        s.map((d, index) => {
                                                                            return (
                                                                                <div key={index}
                                                                                     className={styles.calendar__spekears2_item}>
                                                                                    <div
                                                                                        className={styles.calendar__spekears2_image}>
                                                                                        <img
                                                                                            src={d.image}
                                                                                            alt=""
                                                                                            title=""
                                                                                            loading="lazy"
                                                                                        />
                                                                                    </div>
                                                                                    <div
                                                                                        className={styles.calendar__spekears2_text}>
                                                                                        {
                                                                                            d.name
                                                                                        }
                                                                                        <span>
                                                                                            {
                                                                                                d.company
                                                                                            }
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    {

                                                        t.length > 0 && (
                                                            <div
                                                                className={styles.calendar__spekears2 + ' ' + styles.calendar__spekears2_mt24}>
                                                                <div
                                                                    className={styles.calendar__spekears2_label}>
                                                                    КРУГЛЫЙ СТОЛ
                                                                </div>
                                                                <div
                                                                    className={styles.calendar__spekears2_list}>
                                                                    {
                                                                        t.map((d, index) => {
                                                                            return (
                                                                                <div key={index}
                                                                                     className={styles.calendar__spekears2_item}>
                                                                                    <div
                                                                                        className={styles.calendar__spekears2_image}>
                                                                                        <img
                                                                                            src={d.image}
                                                                                            alt=""
                                                                                            title=""
                                                                                            loading="lazy"
                                                                                        />
                                                                                    </div>
                                                                                    <div
                                                                                        className={styles.calendar__spekears2_text}>
                                                                                        {
                                                                                            d.name
                                                                                        }
                                                                                        <span>
                                                                                            {
                                                                                                d.company
                                                                                            }
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        )
                                    } else {
                                        if (d.speakers_image !== undefined) {
                                            return (
                                                <div key={key}
                                                     className={styles.calendar__tr + ' ' + styles.calendar__tr_hover}
                                                     style={{backgroundColor: bgColor}}
                                                     onClick={() => open_calendar(index)}>
                                                    <div className={styles.calendar__time}>
                                                        {
                                                            d.time
                                                        }
                                                    </div>
                                                    <div className={styles.calendar__speakers}>
                                                        <div
                                                            className={styles.calendar__speakers_img}>
                                                            <img src={d.speakers_image[0].url}
                                                                 alt="" title="" loading="lazy"/>
                                                        </div>
                                                        <div
                                                            className={styles.calendar__speakers_name}>
                                                            {
                                                                d.speakers_names
                                                            }
                                                            <span>{d.speakers_company}</span>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={styles.calendar__name + ' ' + (type === 'qa' ? '' : styles.calendar_name_2)}>
                                                        {
                                                            d.name
                                                        }
                                                    </div>
                                                    {
                                                        type === 'qa' && (
                                                            <a href={qa_link} target="_blank"
                                                               rel="noreferrer"
                                                               className={styles.calendar__qa_link}>Задать
                                                                вопрос</a>)
                                                    }
                                                </div>
                                            )
                                        }
                                    }

                                }
                                if (type === 'empty') {
                                    let bgColor = '#E7ECFE';
                                    return (
                                        <div key={key} className={styles.calendar__tr}
                                             style={{backgroundColor: bgColor, cursor: 'default'}}>
                                            <div className={styles.calendar__time}>
                                                {
                                                    d.time
                                                }
                                            </div>
                                            <div className={styles.calendar__speakers}>
                                                <div className={styles.calendar__speakers_img}>
                                                    <img src={empty} alt="" title=""/>
                                                </div>
                                                <div className={styles.calendar__speakers_name}>
                                                    <span>Спикер вот-вот появится</span>
                                                </div>
                                            </div>
                                            <div className={styles.calendar__name}>
                                                {
                                                    d.name
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                                return null;

                            })
                        }
                    </div>
                </Wrap>
            </Container>
        </section>
    )
}