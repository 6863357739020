import React, {useContext, useEffect} from 'react'
import {appContext} from "../context/app/appContext";
import loading from '../images/b919ae5e057a209f3f961c352f706353.gif';
import {Header} from "../sections/header/Header";
import {Promo} from "../sections/promo/Promo";
import {Promo2} from "../sections/promo2/Promo2";
import {Partners} from "../sections/partners/Partners";
import {Calendar} from "../sections/calendar/Calendar";
import {Speakers} from "../sections/speakers/Speakers";
import {About} from "../sections/about/About";
import {Playlists} from "../sections/playlists/Playlists";
import {Reg} from "../sections/reg/Reg";
import {Footer} from "../sections/footer/Footer";
import {Popup} from "../sections/popup/Popup";
import {Who} from "../sections/who/Who";

function InitialApp(props) {
    const {appState, app_to_ready} = useContext(appContext)
    useEffect(() => {
        if (!appState.appReady) {
            app_to_ready()
        }
    })
    if (appState.appReady) {
        if (!appState.popupThank){
            return (
                <React.Fragment>
                    <Header/>
                    <Promo/>
                    <Who/>
                    <Reg/>
                    <Calendar/>
                    <Speakers/>
                    <Partners/>
                    <Playlists/>
                    <Reg/>
                    <Footer/>
                    <Popup/>
                </React.Fragment>
            );
        } else {

            return (
                <React.Fragment>
                    <Header/>
                    <Promo2/>
                    <Partners/>
                    <Calendar/>
                    <Speakers/>
                    <About/>
                    <Playlists/>
                    <Reg/>

                    <Footer/>
                    <Popup/>
                </React.Fragment>
            );
        }
    } else {
        return (<div className="app-not-ready"><img src={loading} alt="" title=""/></div>);
    }
}

export default InitialApp;