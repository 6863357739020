import styles from "./Reg.module.css";
import Container from "../../ui/container/Container";
import Wrap from "../../ui/wrap/Wrap";
import React, {useContext} from "react";
import {appContext} from "../../context/app/appContext";

export const Reg = () => {
    const {appState, get_config_value, send_tp_reg} = useContext(appContext)

    if (appState.popupThank){
        //return null
    }
    return (
        <section className={styles.reg}>
            <Container>
                <Wrap>
                    <div className={styles.reg__text}>
                        <div className={styles.reg__desc}>
                            {get_config_value(appState.options, 'text4')}
                        </div>
                        {
                            appState.popupThank && (
                                <a href={appState.telegram} className={styles.reg__btn} target="_blank" rel="noreferrer">
                                    Вступить в telegram-канал
                                </a>
                            )
                        }
                        {
                            !appState.popupThank && (
                                <a className={styles.reg__btn} onClick={() => send_tp_reg()} href="https://www.travelpayouts.com/ru/" target="_blank" rel="noreferrer">
                                    Регистрация в Travelpayouts
                                </a>
                            )
                        }
                    </div>
                </Wrap>
            </Container>
        </section>
    )
}