import styles from "./Promo.module.css";
import Container from "../../ui/container/Container";
import Wrap from "../../ui/wrap/Wrap";
import React, {useContext} from "react";
import {appContext} from "../../context/app/appContext";
import ben1 from '../../images/ben1.svg';
import ben2 from '../../images/ben2.svg';
import ben3 from '../../images/ben3.svg';

export const Promo = () => {
    const {appState, get_config_value, reg_popup, promo_view, animation} = useContext(appContext)
    const isMobile = window.innerWidth < 992 ? true : false
    let i1 = get_config_value(appState.options, 'image1')
    let i2 = get_config_value(appState.options, 'image2')
    let i3 = get_config_value(appState.options, 'image3')
    let i4 = get_config_value(appState.options, 'image4')
    const handleScroll = () => {
        if (appState.promoView) {
            if (window.scrollY <= 400) {
                promo_view(false)
            }
        } else {
            if (window.scrollY > 400) {
                promo_view(true)
            }
        }
    };
    const handleAnimation = () => {
        animation()

    }
    React.useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    });
    React.useEffect(() => {
        let timer1 = setTimeout(() => handleAnimation(), 4000);

        return () => {
            clearTimeout(timer1);
        };
    });

    if (isMobile) {
        return (
            <section className={styles.promo_mobile} id="Promo">
                <Container>
                    <Wrap>
                        <div
                            className={styles.promo_mobile__image1 + ' ' + (appState.animationImage === 1 ? styles.show : styles.hide)}>
                            <img src={i1} alt="" title=""
                                 className={appState.animationImage === 1 ? styles.start_animation2 : ''}/>
                        </div>
                        <div
                            className={styles.promo_mobile__image2 + ' ' + (appState.animationImage === 2 ? styles.show : styles.hide)}>
                            <img src={i2} alt="" title=""
                                 className={appState.animationImage === 2 ? styles.start_animation2 : ''}/>
                        </div>
                        <div
                            className={styles.promo_mobile__image3 + ' ' + (appState.animationImage === 3 ? styles.show : styles.hide)}>
                            <img src={i3} alt="" title=""
                                 className={appState.animationImage === 3 ? styles.start_animation2 : ''}/>
                        </div>
                        <div
                            className={styles.promo_mobile__image4 + ' ' + (appState.animationImage === 4 ? styles.show : styles.hide)}>
                            <img src={i4} alt="" title=""
                                 className={appState.animationImage === 4 ? styles.start_animation2 : ''}/>
                        </div>

                        <div className={styles.promo_mobile__title}>
                            {get_config_value(appState.options, 'text1_1')}
                            <strong>{get_config_value(appState.options, 'text1_2')}</strong>
                            {get_config_value(appState.options, 'text1_3')}
                        </div>
                        <div className={styles.promo_mobile__desc}>{get_config_value(appState.options, 'text3')}</div>
                        {
                            appState.popupThank && (
                                <a href={appState.telegram} className={styles.promo_mobile__btn} target="_blank"
                                   rel="noreferrer">
                                    Telegram-канал для участников
                                </a>
                            )
                        }
                        {
                            !appState.popupThank && (
                                <div className={styles.promo_mobile__btn} onClick={() => reg_popup()}>Получить материалы
                                    бесплатно</div>
                            )
                        }
                        <div className={styles.promo_mobile__about}>
                            <div className={styles.promo_mobile__about_box}>

                                <div className={styles.promo_mobile__about_box_heading}>
                                    <span>TPAS —</span>
                                    это ежегодный саммит Travelpayouts
                                    для представителей турбизнеса <br/>и экспертов отрасли.
                                </div>
                                <div className={styles.promo_mobile__about_box_paragraph}>
                                    Мы собираем главных игроков на рынке путешествий, — таких как Booking.com,
                                    Vandrouki и Туту.ру, — чтобы обсудить самые актуальные вопросы монетизации и
                                    онлайн-продвижения тревел-бизнеса.
                                </div>
                                <div className={styles.promo_mobile__about_box_paragraph}>
                                    В фокусе саммита в 2021 году — главные тренды восстановления туризма и уроки
                                    пандемии, новые источники дохода, способы развития собственного бренда и оптимизации
                                    затрат на привлечение лояльной аудитории.
                                </div>
                                <div className={styles.promo_mobile__about_box_paragraph}>
                                    Саммит прошёл онлайн <strong>7 октября</strong>
                                    c <strong>11:00 до 19:00 (МСК)</strong>. В программе — круглые столы и доклады от
                                    Авиасейлс,
                                    <strong>Travelata, АТОР, Тинькофф Путешествия, Яндекс Путешествия,
                                        Слетать.ру</strong> и других экспертов, которые рассказали
                                    о будущем тревел-индустрии.
                                </div>
                            </div>
                            <div className={styles.promo_mobile__about_benefits}>
                                <div className={styles.promo_mobile__about_benefits_heading}>Цифры</div>
                                <div className={styles.promo_mobile__about_benefits_item}>
                                    <div className={styles.promo_mobile__about_benefits_icon}>
                                        <img src={ben1} alt="" title="" loading="lazy"/>
                                    </div>
                                    <div className={styles.promo_mobile__about_benefits_desc}>
                                        проводится Travelpayouts Affiliate Summit
                                    </div>
                                </div>
                                <div className={styles.promo_mobile__about_benefits_item}>
                                    <div className={styles.promo_mobile__about_benefits_icon}>
                                        <img src={ben2} alt="" title="" loading="lazy"/>
                                    </div>
                                    <div className={styles.about__benefits_desc}>
                                        докладов с записями выступлений
                                    </div>
                                </div>
                                <div className={styles.promo_mobile__about_benefits_item}>
                                    <div className={styles.promo_mobile__about_benefits_icon}>
                                        <img src={ben3} alt="" title="" loading="lazy"/>
                                    </div>
                                    <div className={styles.promo_mobile__about_benefits_desc}>
                                        участников
                                        на прошлом саммите
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Wrap>
                </Container>
            </section>
        )
    } else {


        return (
            <React.Fragment>
                <section className={styles.promo} id="Promo">
                    <Container>
                        <Wrap>
                            <div className={styles.promo__text}>
                                <div className={styles.promo__title}>
                                    {get_config_value(appState.options, 'text1_1')}
                                    <strong>{get_config_value(appState.options, 'text1_2')}</strong>
                                    {get_config_value(appState.options, 'text1_3')}
                                </div>
                                <div className={styles.promo__desc}>{get_config_value(appState.options, 'text3')}</div>
                                {
                                    appState.popupThank && (
                                        <a href={appState.telegram} className={styles.promo__btn} target="_blank"
                                           rel="noreferrer">
                                            Telegram-канал для участников
                                        </a>
                                    )
                                }
                                {
                                    !appState.popupThank && (
                                        <div className={styles.promo__btn} onClick={() => reg_popup()}>Получить
                                            материалы бесплатно</div>
                                    )
                                }

                                <div className={styles.promo__image1}>
                                    <img src={i1} alt="" title=""
                                         className={appState.animationImage === 1 ? styles.start_animation : ''}/>
                                </div>
                                <div className={styles.promo__image2}>
                                    <img src={i2} alt="" title=""
                                         className={appState.animationImage === 2 ? styles.start_animation : ''}/>
                                </div>
                                <div className={styles.promo__image3}>
                                    <img src={i3} alt="" title=""
                                         className={appState.animationImage === 3 ? styles.start_animation : ''}/>
                                </div>
                                <div className={styles.promo__image4}>
                                    <img src={i4} alt="" title=""
                                         className={appState.animationImage === 4 ? styles.start_animation : ''}/>
                                </div>
                            </div>
                            <div className={styles.about}>
                                <div className={styles.about__box}>
                                    <div className={styles.about__box_col}>
                                        <div className={styles.about__box_heading}>
                                            <span>TPAS —</span>
                                            это ежегодный саммит Travelpayouts
                                            для представителей турбизнеса <br/>и экспертов отрасли.
                                        </div>
                                        <div className={styles.about__box_paragraph}>
                                            Мы собираем главных игроков на рынке путешествий, — таких как Booking.com,
                                            Vandrouki и Туту.ру, — чтобы обсудить самые актуальные вопросы монетизации и
                                            онлайн-продвижения тревел-бизнеса.
                                        </div>
                                    </div>
                                    <div className={styles.about__box_col}>
                                        <div className={styles.about__box_paragraph}>
                                            В фокусе саммита в 2021 году — главные  тренды восстановления туризма и уроки пандемии, новые источники дохода, способы развития собственного бренда и оптимизации затрат на привлечение лояльной аудитории.
                                        </div>
                                        <div className={styles.about__box_paragraph}>
                                            Саммит прошёл онлайн <strong>7 октября</strong> <br/>
                                            c <strong>11:00 до 19:00 (МСК)</strong>. В программе — круглые столы и
                                            доклады <br/> от <strong>Авиасейлс,
                                            Travelata, АТОР, Тинькофф Путешествия, Яндекс Путешествия,
                                            Слетать.ру</strong> и других экспертов, которые рассказали <br/>
                                            о будущем тревел-индустрии.
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.about__benefits}>
                                    <div className={styles.about__benefits_heading}>Цифры</div>
                                    <div className={styles.about__benefits_item}>
                                        <div className={styles.about__benefits_icon}>
                                            <img src={ben1} alt="" title="" loading="lazy"/>
                                        </div>
                                        <div className={styles.about__benefits_desc}>
                                            проводится Travelpayouts Affiliate Summit
                                        </div>
                                    </div>
                                    <div className={styles.about__benefits_item}>
                                        <div className={styles.about__benefits_icon}>
                                            <img src={ben2} alt="" title="" loading="lazy"/>
                                        </div>
                                        <div className={styles.about__benefits_desc}>
                                            докладов с записями выступлений
                                        </div>
                                    </div>
                                    <div className={styles.about__benefits_item}>
                                        <div className={styles.about__benefits_icon}>
                                            <img src={ben3} alt="" title="" loading="lazy"/>
                                        </div>
                                        <div className={styles.about__benefits_desc}>
                                            участников
                                            на прошлом саммите
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Wrap>
                    </Container>
                </section>
            </React.Fragment>
        )
    }
}