import React, {useReducer} from 'react'
import {appContext} from './appContext'
import {appReducer} from './appReducer'
import {
    ANIMATION,
    APP_READY,
    FORM_SEND,
    GET_USER,
    GET_USER2,
    HEADER_VIEW, MENU_CLOSE, MENU_OPEN, OPEN_CALENDAR,
    POPUP_HIDE,
    POPUP_SHOW,
    PROMO_VIEW,
    SELECT_OPEN,
    SELECT_OPTION,
    SET_EMAIL,
    SET_NAME,
    WHO_VIEW,
} from "../types";
import axios from "axios";


export const AppState = ({children}) => {
    /*
    const add_utm = (url) => {
        let new_url = url
        let req_uri = window.location.href.indexOf('?') > 0 ? window.location.href.substr(window.location.href.indexOf('?') + 1) : '';
        if (req_uri.length > 0) {
            new_url = url + '&' + req_uri
        }
        console.log('URL', new_url)
        return new_url
    }
    */
    const api_key = '4f893ee24950069dd823e083338a456d';
    const initialState = {
        appReady: false,
        headerView: false,
        promoView: false,
        whoView: false,
        popup: false,
        testUser: false,
        popupThank: false,
        menuOpen: false,
        animationImage: 1,
        telegram: 'https://tp.click/1Z0',
        user: {
            marker: 0,
            user_id: 0,
            email: '',
        },
        options: {},
        who: {},
        programm: {},
        speakers: {},
        partners: {},
        reps: {},
        calendar: {},
        emptyCalendar: (Math.floor(Math.random() * 2) >= 1) ? true : false,
        form: {
            name: '',
            email: '',
            type: '',
            listType: [
                'Блогер',
                'Представитель тур. бизнеса',
                'Вебмастер',
                'Маркетолог',
                'Другое',
            ],
            errorName: false,
            errorEmail: false,
            errorType: false,
            formReady: false,
            selectOpen: false,
        },

    }
    let is_test_user = false
    const [state, dispatch] = useReducer(appReducer, initialState)


    const get_user = () => {
        if (state.testUser === false && is_test_user === false) {
            is_test_user = true
            axios.get('https://www.travelpayouts.com/api/entry', {
                withCredentials: true,
            }).then(res => {
                console.log(res.data)
                //Отсылам майнд бок регистрацию через tp
                window.mindbox("async", {
                    operation: "Lid.RegistrationOnLanding.TPAS",
                    data: {
                        customer: {
                            area: {
                                ids: {
                                    externalId: "ru"
                                }
                            },
                            email: res.data.email,
                            FullName: '',
                            ids: {
                                marker: res.data.marker
                            },
                            customFields: {
                                clientsType: ''
                            },
                            subscriptions: [
                                {
                                    brand: "Travelpayouts",
                                    pointOfContact: "Email",
                                    isSubscribed: "true"
                                }
                            ]
                        },
                        pointOfContact: "tpas_2021_ru",
                        addProductToList: {
                            product: {
                                ids: {
                                    landings: "tpas_2021_ru"
                                }
                            }
                        }
                    },
                    onSuccess: function () {
                    },
                    onError: function (error) {
                    }
                })
                window.dataLayer.push({
                    event: 'registration',
                    marker: res.data.marker

                });
                window.ym(51491887,'reachGoal','registr_completed');
                window.fbq('track', 'CompleteRegistration', {currency: "USD", value: 30.00});
                localStorage.setItem('summit2021reg', 1);
                localStorage.setItem('summit2021reg_email', res.data.email);
                localStorage.setItem('summit2021reg_marker', res.data.marker);
                console.log('registration tp')
                dispatch({type: GET_USER, payload: {data: res.data}})
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                dispatch({type: GET_USER2, payload: null})
            });
        }
        return '';
    }

    function mamkaGetCookie(name, def) {
        let matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"));
        return matches ? decodeURIComponent(matches[1]) : def;
    }
    const app_to_ready = () => {
        axios.get('https://summit.travelpayouts.com/api/api.php?key=' + api_key + '&type=options&update=0', {
            withCredentials: true,
        }).then(res => {
            if (res.data) {
                var isReg = parseInt(localStorage.getItem('summit2021reg'));
                //Отсылам посещение
                let user_marker = null;
                let attr_parameters = {
                    utm_source: null,
                    utm_medium: null,
                    utm_campaign: null,
                    utm_term: null,
                    utm_content: null,
                    promotion_code_id: null,
                    referral_marker: null,
                    ad_source: null,
                    ad_content: null,
                    tp_referrer: null,
                }
                attr_parameters.utm_source = mamkaGetCookie('utm_source', null);
                attr_parameters.utm_medium = mamkaGetCookie('utm_medium', null);
                attr_parameters.utm_campaign = mamkaGetCookie('utm_campaign', null);
                attr_parameters.utm_term = mamkaGetCookie('utm_term', null);
                attr_parameters.utm_content = mamkaGetCookie('utm_content', null);
                attr_parameters.promotion_code_id = mamkaGetCookie('promotion_code_id', null);
                attr_parameters.referral_marker = mamkaGetCookie('referral_marker', null);
                attr_parameters.ad_source = mamkaGetCookie('ad_source', null);
                attr_parameters.ad_content = mamkaGetCookie('ad_content', null);
                attr_parameters.tp_referrer = mamkaGetCookie('tp_referrer', null);
                user_marker = mamkaGetCookie('partners_marker', null);
                if (user_marker == null) {
                    if (localStorage.getItem('summit2021reg_marker') !== null && parseInt(localStorage.getItem('summit2021reg_marker')) > 0) {
                        user_marker = parseInt(localStorage.getItem('summit2021reg_marker'));
                    }
                }
                if (window.mamka) {
                    try {
                        window.mamka('send_page_view', {
                            marker: user_marker,
                            attr_parameters: attr_parameters,
                        });
                    } catch (e) {
                        console.log(e)
                    }
                    console.log('send_page_view');
                }
                console.log('visit')
                dispatch({
                    type: APP_READY, payload: {
                        options: res.data.options,
                        programm: res.data.programm,
                        speakers: res.data.speakers,
                        partners: res.data.partners,
                        reps: res.data.reps,
                        calendar: res.data.calendar,
                        testUser: (isReg === 1) ? true : false,
                        popupThank: (isReg === 1) ? true : false
                    }
                })
            }
        });
    }
    const send_tp_reg = () => {
        let user_marker = null;
        let attr_parameters = {
            utm_source: null,
            utm_medium: null,
            utm_campaign: null,
            utm_term: null,
            utm_content: null,
            promotion_code_id: null,
            referral_marker: null,
            ad_source: null,
            ad_content: null,
            tp_referrer: null,
        }
        attr_parameters.utm_source = mamkaGetCookie('utm_source', null);
        attr_parameters.utm_medium = mamkaGetCookie('utm_medium', null);
        attr_parameters.utm_campaign = mamkaGetCookie('utm_campaign', null);
        attr_parameters.utm_term = mamkaGetCookie('utm_term', null);
        attr_parameters.utm_content = mamkaGetCookie('utm_content', null);
        attr_parameters.promotion_code_id = mamkaGetCookie('promotion_code_id', null);
        attr_parameters.referral_marker = mamkaGetCookie('referral_marker', null);
        attr_parameters.ad_source = mamkaGetCookie('ad_source', null);
        attr_parameters.ad_content = mamkaGetCookie('ad_content', null);
        attr_parameters.tp_referrer = mamkaGetCookie('tp_referrer', null);
        user_marker = mamkaGetCookie('partners_marker', null);
        if (user_marker == null) {
            if (localStorage.getItem('summit2021reg_marker') !== null && parseInt(localStorage.getItem('summit2021reg_marker')) > 0) {
                user_marker = parseInt(localStorage.getItem('summit2021reg_marker'));
            }
        }
        window.mamka('trackStructEvent', 'summit', 'register', null, null, null, {
            marker: user_marker,
            attr_parameters: attr_parameters,
        });
        console.log('register');
    }

    const get_config_value = (arr, key) => {
        let val = '';
        arr.map((d, index) => {
            if (d.key && d.key === key && val.length === 0) {

                if (d.value === 'image') {
                    //alert(d.image[0].url.toString())
                    val = d.image[0].url.toString()
                } else {
                    if (d.value === 'gallery') {
                        val = d
                    } else {
                        val = d.value
                    }
                }
            }

            return index
        });

        return val
    }
    const reg_popup = () => {
        dispatch({type: POPUP_SHOW, payload: null})
    }
    const header_view = (view) => {
        if (view) {
            dispatch({type: HEADER_VIEW, payload: {headerView: true}})
        } else {
            dispatch({type: HEADER_VIEW, payload: {headerView: false}})
        }
    }
    const promo_view = (view) => {
        if (view) {
            dispatch({type: PROMO_VIEW, payload: {promoView: true}})
        } else {
            dispatch({type: PROMO_VIEW, payload: {promoView: false}})
        }
    }
    const who_view = (view) => {
        if (view) {
            dispatch({type: WHO_VIEW, payload: {whoView: true}})
        } else {
            dispatch({type: WHO_VIEW, payload: {whoView: false}})
        }
    }
    const reg_popup_close = () => {
        dispatch({type: POPUP_HIDE, payload: null})
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        //Отсылам майндбокc регистрацию через mb
        window.mindbox("async", {
            operation: "Lid.RegistrationOnLanding.TPAS",
            data: {
                customer: {
                    email: state.form.email,
                    area: {
                        ids: {
                            externalId: "ru"
                        }
                    },
                    FullName: state.form.name,
                    customFields: {
                        clientsType: state.form.type
                    },
                    subscriptions: [
                        {
                            brand: "Travelpayouts",
                            pointOfContact: "Email",
                            isSubscribed: "true"
                        }
                    ]
                },
                pointOfContact: "tpas_2021_ru",
                addProductToList: {
                    product: {
                        ids: {
                            landings: "tpas_2021_ru"
                        }
                    }
                }
            },
            onSuccess: function () {
            },
            onError: function (error) {
            }
        })
        window.dataLayer.push({
            event: 'registration',
        });
        window.ym(51491887,'reachGoal','registr_completed');
        window.fbq('track', 'CompleteRegistration', {currency: "USD", value: 30.00});
        localStorage.setItem('summit2021reg', 1);
        localStorage.setItem('summit2021reg_email', state.form.email);
        localStorage.setItem('summit2021reg_name', state.form.name);
        localStorage.setItem('summit2021reg_type', state.form.type);
        console.log('registration mb')
        let form = {
            ...state,
            name: '',
            email: '',
            type: '',
            errorName: false,
            errorEmail: false,
            errorType: false,
            formReady: false,
            selectOpen: false,
        }
        dispatch({type: FORM_SEND, payload: {form: form}})
    }
    const form_ready = (f) => {
        let formReady = false
        if (f === 'name') {
            if (state.form.email.length > 0 && state.form.type.length > 0) {
                if (!state.form.errorEmail && !state.form.errorType) {
                    formReady = true
                }
            }
        }
        if (f === 'email') {
            if (state.form.name.length > 0 && state.form.type.length > 0) {
                if (!state.form.errorName && !state.form.errorType) {
                    formReady = true
                }
            }
        }
        if (f === 'type') {
            if (state.form.name.length > 0 && state.form.email.length > 0) {
                if (!state.form.errorName && !state.form.errorEmail) {
                    formReady = true
                }
            }
        }
        return formReady
    }
    const onNameChange = (e) => {
        e.preventDefault()
        let val = e.target.value
        let er = false
        if (val.toString().length >= 3) {
        } else {
            if (val.toString() !== '') {
                er = true
            }
        }
        let formReady = (!er && form_ready('name')) ? true : false;
        dispatch({type: SET_NAME, payload: {form: {...state.form, name: val, errorName: er, formReady: formReady}}})
    }
    const validateEmail = (email) => {
        var re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        return re.test(String(email).toLowerCase());
    }
    const onEmailChange = (e) => {
        e.preventDefault()

        let val = e.target.value
        let er = false
        if (val.toString().length >= 3) {
            if (!validateEmail(val)) {
                er = true
            }
        } else {
            if (val.toString() !== '') {
                er = true
            }
        }
        let formReady = (!er && form_ready('email')) ? true : false;
        dispatch({type: SET_EMAIL, payload: {form: {...state.form, email: val, errorEmail: er, formReady: formReady}}})
    }
    const handleSelect = () => {
        dispatch({
            type: SELECT_OPEN,
            payload: {form: {...state.form, selectOpen: !state.form.selectOpen}}
        })
    }
    const handleSelectOption = (val) => {
        let er = false
        if (val.toString().length >= 3) {

        } else {
            if (val.toString() !== '') {
                er = true
            }
        }
        let formReady = (!er && form_ready('type')) ? true : false;
        dispatch({
            type: SELECT_OPTION,
            payload: {form: {...state.form, type: val, selectOpen: false, errorType: er, formReady: formReady}}
        })
    }

    const handleSelect2 = (e) => {
        var val = e.target.value
        let er = false
        if (val.toString().length >= 3) {

        } else {
            if (val.toString() !== '') {
                er = true
            }
        }
        let formReady = (!er && form_ready('type')) ? true : false;
        dispatch({
            type: SELECT_OPTION,
            payload: {form: {...state.form, type: val, selectOpen: false, errorType: er, formReady: formReady}}
        })
    }


    const menu_open = () => {
        dispatch({
            type: MENU_OPEN,
            payload: null
        })
    }
    const menu_close = () => {
        dispatch({
            type: MENU_CLOSE,
            payload: null
        })
    }

    const animation = () => {
        let animationImage = state.animationImage
        animationImage++
        if (animationImage > 4) {
            animationImage = 1;
        }
        dispatch({
            type: ANIMATION,
            payload: {animationImage: animationImage}
        })
    }
    const open_calendar = (index) => {
        if (state.popupThank) {
            let open = false
            let calendar = state.calendar
            if (calendar[index].isOpen === undefined) {
                open = true
            } else {
                open = !state.calendar[index].isOpen
            }
            calendar[index].isOpen = open
            dispatch({
                type: OPEN_CALENDAR,
                payload: {calendar: calendar}
            })
        }
    }

    return (
        <appContext.Provider
            value={{
                app_to_ready,
                get_config_value,
                reg_popup,
                header_view,
                promo_view,
                who_view,
                reg_popup_close,
                get_user,
                handleSubmit,
                onNameChange,
                onEmailChange,
                handleSelect,
                handleSelectOption,
                menu_open,
                menu_close,
                handleSelect2,
                animation,
                open_calendar,
                send_tp_reg,
                appState: state
            }}>
            {children}
        </appContext.Provider>
    )
}
