import {AppState} from './context/app/AppState'
import './ui/reset/Reset.css'
import './index.css'
import InitialApp from "./hoc/InitialApp";

function App() {

    return (
        <AppState>
            <InitialApp>

            </InitialApp>
        </AppState>
    );
}

export default App;
