import {
    ANIMATION,
    APP_READY, FORM_SEND,
    GET_USER,
    GET_USER2,
    HEADER_VIEW, MENU_CLOSE, MENU_OPEN, OPEN_CALENDAR,
    POPUP_HIDE,
    POPUP_SHOW,
    PROMO_VIEW,
    SELECT_OPEN,
    SELECT_OPTION,
    SET_EMAIL,
    SET_NAME, TEST_USER,
    WHO_VIEW
} from "../types";

const handlers = {
    [HEADER_VIEW]: (state, {payload}) => ({
        ...state,
        headerView: payload.headerView
    }),
    [PROMO_VIEW]: (state, {payload}) => ({
        ...state,
        promoView: payload.promoView
    }),
    [WHO_VIEW]: (state, {payload}) => ({
        ...state,
        whoView: payload.whoView
    }),
    [GET_USER]: (state, {payload}) => ({
        ...state,
        user: {marker: payload.data.marker, user_id: payload.data.user_id, email: payload.data.email},
        testUser: true,
        popupThank: true
    }),
    [TEST_USER]: (state, {payload}) => ({
        testUser: true,
    }),
    [GET_USER2]: (state, {payload}) => ({
        ...state,
        testUser: true
    }),
    [APP_READY]: (state, {payload}) => ({
        ...state,
        options: payload.options,
        programm: payload.programm,
        speakers: payload.speakers,
        partners: payload.partners,
        calendar: payload.calendar,
        testUser: payload.testUser,
        popupThank: payload.popupThank,
        reps: payload.reps,
        appReady: true,
    }),
    [POPUP_SHOW]: (state) => ({
        ...state,
        popup: true,
        menuOpen: false
    }),
    [POPUP_HIDE]: (state) => ({
        ...state,
        popup: false,
        menuOpen: false
    }),
    [SELECT_OPEN]: (state, {payload}) => ({
        ...state,
        form: payload.form
    }),
    [SELECT_OPTION]: (state, {payload}) => ({
        ...state,
        form: payload.form
    }),
    [SET_NAME]: (state, {payload}) => ({
        ...state,
        form: payload.form
    }),
    [SET_EMAIL]: (state, {payload}) => ({
        ...state,
        form: payload.form
    }),
    [FORM_SEND]: (state, {payload}) => ({
        ...state,
        form: payload.form,
        popupThank: true,
    }),
    [MENU_OPEN]: (state, {payload}) => ({
        ...state,
        menuOpen: true
    }),
    [MENU_CLOSE]: (state, {payload}) => ({
        ...state,
        menuOpen: false
    }),
    [ANIMATION]: (state, {payload}) => ({
        ...state,
        animationImage: payload.animationImage
    }),
    [OPEN_CALENDAR]: (state, {payload}) => ({
        ...state,
        calendar: payload.calendar
    }),
    DEFAULT: state => state
}

export const appReducer = (state, action) => {
    const handler = handlers[action.type] || handlers.DEFAULT
    return handler(state, action)
}