import styles from "./Popup.module.css";
import React, {useContext} from "react";
import {appContext} from "../../context/app/appContext";
import loading from '../../images/b919ae5e057a209f3f961c352f706353.gif';

export const Popup = () => {
    const {
        appState,
        get_user,
        reg_popup_close,
        handleSubmit,
        onNameChange,
        onEmailChange,
        handleSelect,
        handleSelectOption,
        handleSelect2
    } = useContext(appContext)
    const isMobile = window.innerWidth < 992 ? true : false

    if (appState.popup) {
        if (appState.testUser) {
            if (appState.popupThank) {
                return (
                    <div className={styles.popup}>
                        <div className={styles.popup__back} onClick={() => reg_popup_close()}>&nbsp;</div>
                        <div className={styles.popup__form}>
                            <div className={styles.popup__thank}>
                                <div className={styles.popup__thank_close} onClick={() => reg_popup_close()}>
                                    <svg width="64" height="64" viewBox="0 0 64 64" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M25 25L39 39M39 25L25 39" stroke="black" strokeWidth="2"/>
                                    </svg>
                                </div>
                                <div className={styles.popup__thank_heading}>
                                    Поздравляем!
                                </div>
                                <div className={styles.popup__thank_desc}>
                                    Теперь все материалы доступны на лендинге.
                                </div>

                                <a href={appState.telegram} className={styles.popup__thank_btn} target="_blank"
                                   rel="noreferrer">
                                    Telegram-чат для участников
                                </a>
                            </div>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className={styles.popup}>
                        <div className={styles.popup__back} onClick={() => reg_popup_close()}>&nbsp;</div>
                        <div className={styles.popup__form}>
                            <div className={styles.popup_reg}>
                                <div className={styles.popup_reg_close} onClick={() => reg_popup_close()}>
                                    <svg width="64" height="64" viewBox="0 0 64 64" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M25 25L39 39M39 25L25 39" stroke="black" strokeWidth="2"/>
                                    </svg>
                                </div>
                                <div className={styles.popup_reg_wrap}>
                                    <div className={styles.popup_reg_heading}>
                                        Регистрация
                                    </div>
                                    <div className={styles.popup__thank_desc}>
                                        Зарегистрируйтесь, чтобы получить материалы. Это бесплатно.
                                    </div>
                                    <form onSubmit={handleSubmit}>
                                        <div className={styles.popup_reg_field}>
                                            <input type="text" name="name" placeholder="Имя"
                                                   onChange={onNameChange}
                                                   value={appState.form.name}
                                                   className={(appState.form.errorName ? styles.popup_reg_input_error : '')}/>
                                        </div>
                                        <div className={styles.popup_reg_field}>
                                            <input type="email" name="email" placeholder="Email"
                                                   onChange={onEmailChange} value={appState.form.email}
                                                   className={(appState.form.errorEmail ? styles.popup_reg_input_error : '')}
                                            />
                                        </div>
                                        <div className={styles.popup_reg_field}>
                                            {
                                                isMobile && (
                                                    <select value={appState.form.type} onChange={(e) => handleSelect2(e)}>
                                                        <option value={''}>Род деятельности</option>
                                                        {
                                                            appState.form.listType.map((d, index) => (
                                                                <option key={index} value={d}>{d}</option>))
                                                        }
                                                    </select>
                                                )
                                            }
                                            {
                                                !isMobile && (

                                                    <div className={styles.popup_reg_select}>
                                                        <div
                                                            className={(appState.form.selectOpen) ? styles.popup_reg_select_current_open : styles.popup_reg_select_current}
                                                            onClick={handleSelect}>
                                                            {
                                                                appState.form.type.length > 0 ? appState.form.type : 'Род деятельности'
                                                            }
                                                        </div>
                                                        {
                                                            appState.form.selectOpen && (
                                                                <div className={styles.popup_reg_select_vals}>
                                                                    {
                                                                        appState.form.listType.map((d, index) => (
                                                                            <div key={index}
                                                                                 onClick={() => handleSelectOption(d)}
                                                                                 className={styles.popup_reg_select_vals_option}>{d}</div>))
                                                                    }
                                                                </div>
                                                            )
                                                        }
                                                    </div>)
                                            }
                                        </div>
                                        <button type="submit"
                                                className={(!appState.form.formReady) ? styles.popup_reg_btn_disabled : styles.popup_reg_btn}
                                                disabled={(!appState.form.formReady) ? 'disabled' : ''}
                                        >
                                            Получить материалы саммита
                                        </button>
                                        {
                                            (appState.form.errorEmail || appState.form.errorName || appState.form.errorType) && (
                                                <div className={styles.popup_reg_error}>
                                                    Для регистрации необходимо заполнить все поля. Если не получается,
                                                    обратитесь
                                                    в <a href="mailto:support.travelpayouts.com" target="_blank"
                                                         rel="noreferrer">службу поддержки</a>
                                                </div>
                                            )
                                        }
                                        {
                                            (!appState.form.errorEmail && !appState.form.errorName && !appState.form.errorType) && (
                                                <div className={styles.popup_reg_small}>
                                                    Нажимая на кнопку «Получить материалы саммита», <br/>
                                                    вы даёте согласие на <a href="https://tp.click/1YZ" target="_blank"
                                                                            rel="noreferrer"> обработку своих <br/>
                                                    персональных данных</a>
                                                </div>
                                            )
                                        }
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        } else {
            get_user()
            return (
                <div className={styles.popup}>
                    <div className={styles.popup__back} onClick={() => reg_popup_close()}>&nbsp;</div>
                    <div className={styles.popup__form}>
                        <div className={styles.popup__loading}>
                            <img src={loading} alt="" title=""/>
                        </div>
                    </div>
                </div>
            )
        }
    } else {
        return null
    }
}