import styles from "./Partners.module.css";
import Container from "../../ui/container/Container";
import Wrap from "../../ui/wrap/Wrap";
import React, {useContext} from "react";
import {appContext} from "../../context/app/appContext";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/swiper.min.css";
import SwiperCore, {
    Autoplay, Pagination, Navigation
} from "swiper/core";

SwiperCore.use([Autoplay, Pagination, Navigation]);

export const Partners = () => {
    const {appState,send_tp_reg} = useContext(appContext)
    const isMobile = window.innerWidth < 992 ? true : false
    if (appState.partners.length > 0) {
        let arr = [];
        let count = 10;
        for (let i = 1; i <= Math.ceil(appState.partners.length / count); i++) {
            arr.push(i);
        }
        if (isMobile) {
            return (
                <section className={styles.partners} id="Partners">
                    <Container>
                        <Wrap>
                            <div className={styles.partners__title}>Участники и партнёры саммита</div>
                            <div className={styles.partners__items}>
                                {
                                    appState.partners.map((d, index) => {
                                        let key = 'partners' + index
                                        return (
                                            <div key={key}
                                                 className={styles.partners__item}>
                                                <img src={d.logo[0].url} alt="" title="" loading="lazy"/>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            {
                                appState.popupThank && (
                                    <a href={appState.telegram} className={styles.partners__btn} target="_blank" rel="noreferrer">
                                        Telegram-канал для участников
                                    </a>
                                )
                            }
                            {
                                !appState.popupThank && (
                                    <a className={styles.partners__btn} onClick={() => send_tp_reg()} href="https://www.travelpayouts.com/ru/" target="_blank" rel="noreferrer">
                                        Регистрация в Travelpayouts
                                    </a>
                                )
                            }
                        </Wrap>
                    </Container>
                </section>
            )
        } else {
            return (
                <section className={styles.partners} id="Partners">
                    <Container>
                        <Wrap>
                            <div className={styles.partners__title}>Участники и партнёры саммита</div>
                            <div className={styles.partners__slider}>
                                <div className={styles.partners__prev} id="partners-prev">
                                    <svg width="21" height="44" viewBox="0 0 21 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19 1.99976L3 21.9998L19 41.9998" stroke="white" strokeWidth="4"/>
                                    </svg>

                                </div>
                                <Swiper
                                    spaceBetween={0}
                                    slidesPerView={1}
                                    loop={true}
                                    navigation={{
                                        'prevEl': '#partners-prev',
                                        'nextEl': '#partners-next',
                                    }}
                                >
                                    {
                                        arr.map((i, index2) => {
                                            let key = 'partnersSlide' + index2
                                            return (
                                                <SwiperSlide key={key}>
                                                    <div className={styles.partners__items}>
                                                        {
                                                            appState.partners.map((d, index) => {
                                                                let key = 'partners' + index
                                                                let j = index + 1
                                                                if (j > count * (i - 1) && j <= count * i) {
                                                                    return (
                                                                        <div key={key}
                                                                             className={styles.partners__item}>
                                                                            <img src={d.logo[0].url} alt="" title="" loading="lazy"/>
                                                                        </div>
                                                                    )
                                                                } else {
                                                                    return null
                                                                }
                                                            })
                                                        }
                                                    </div>
                                                </SwiperSlide>)
                                        })
                                    }
                                </Swiper>
                                <div className={styles.partners__next} id="partners-next">
                                    <svg width="21" height="44" viewBox="0 0 21 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2 1.99976L18 21.9998L2 41.9998" stroke="white" strokeWidth="4"/>
                                    </svg>

                                </div>
                            </div>
                            {
                                appState.popupThank && (
                                    <a href={appState.telegram} className={styles.partners__btn} target="_blank" rel="noreferrer">
                                        Telegram-канал для участников
                                    </a>
                                )
                            }
                            {
                                !appState.popupThank && (
                                    <a className={styles.partners__btn} onClick={() => send_tp_reg()} href="https://www.travelpayouts.com/ru/" target="_blank" rel="noreferrer">
                                        Регистрация в Travelpayouts
                                    </a>
                                )
                            }
                        </Wrap>
                    </Container>
                </section>
            )
        }
    } else {
        return (<div></div>)
    }
}