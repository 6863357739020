import styles from "./About.module.css";
import Container from "../../ui/container/Container";
import Wrap from "../../ui/wrap/Wrap";
import ben1 from "../../images/ben1.svg";
import ben2 from "../../images/ben2.svg";
import ben3 from "../../images/ben3.svg";

export const About = () => {
    return (
        <section className={styles.about}>
            <Container>
                <Wrap>
                    <div className={styles.about__title}>
                        О саммите
                    </div>
                </Wrap>
            </Container>
            <div className={styles.about__gray}>
                <Container>
                    <Wrap>
                        <div className={styles.about__flex}>
                            <div className={styles.about__box}>
                                <div className={styles.about__box_col}>
                                    <div className={styles.about__box_heading}>
                                        <span>TPAS —</span>
                                        это ежегодный саммит Travelpayouts
                                        для представителей турбизнеса <br/>и экспертов отрасли.
                                    </div>
                                    <div className={styles.about__box_paragraph}>
                                        Мы собираем главных игроков на рынке путешествий, — таких как Booking.com,
                                        Vandrouki и Tutu.ru, — чтобы обсудить самые актуальные вопросы монетизации и
                                        онлайн-продвижения тревел-бизнеса.
                                    </div>
                                </div>
                                <div className={styles.about__box_col}>
                                    <div className={styles.about__box_paragraph}>
                                        В фокусе саммита в 2021 году — главные тренды восстановления туризма и уроки
                                        пандемии, новые источники дохода, способы развития собственного бренда и
                                        оптимизации затрат на привлечение лояльной аудитории.
                                    </div>
                                    <div className={styles.about__box_paragraph}>
                                        Саммит прошёл онлайн <strong>7 октября</strong> <br/>
                                        c <strong>11:00 до 19:00 (МСК)</strong>. В программе — круглые столы и
                                        доклады <br/> от <strong>Авиасейлс,
                                        Travelata, АТОР, Тинькофф Путешествия, Яндекс Путешествия, Слетать.ру</strong> и
                                        других экспертов, которые рассказали <br/>
                                        о будущем тревел-индустрии.
                                    </div>
                                </div>
                            </div>
                            <div className={styles.about__benefits}>
                                <div className={styles.about__benefits_heading}>Цифры</div>
                                <div className={styles.about__benefits_item}>
                                    <div className={styles.about__benefits_icon}>
                                        <img src={ben1} alt="" title="" loading="lazy"/>
                                    </div>
                                    <div className={styles.about__benefits_desc}>
                                        проводится Travelpayouts Affiliate Summit
                                    </div>
                                </div>
                                <div className={styles.about__benefits_item}>
                                    <div className={styles.about__benefits_icon}>
                                        <img src={ben2} alt="" title="" loading="lazy"/>
                                    </div>
                                    <div className={styles.about__benefits_desc}>
                                        докладов с записями выступлений
                                    </div>
                                </div>
                                <div className={styles.about__benefits_item}>
                                    <div className={styles.about__benefits_icon}>
                                        <img src={ben3} alt="" title="" loading="lazy"/>
                                    </div>
                                    <div className={styles.about__benefits_desc}>
                                        участников
                                        на прошлом саммите
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Wrap>
                </Container>
            </div>
        </section>
    )
}