import styles from "./Who.module.css";
import Container from "../../ui/container/Container";
import Wrap from "../../ui/wrap/Wrap";
import React, {useContext} from "react";
import {appContext} from "../../context/app/appContext";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/swiper.min.css";
import SwiperCore, {
    Autoplay, Pagination, Navigation
} from 'swiper/core';

SwiperCore.use([Autoplay, Pagination, Navigation]);

export const Who = () => {
    const {appState} = useContext(appContext)
    const isMobile = window.innerWidth < 992 ? true : false
    if (appState.popupThank) {
        return null
    }
    if (isMobile) {
        return (
            <section className={styles.who_mobile} id="Who">
                <Container>
                    <Wrap>
                        <div className={styles.who_mobile__title}>
                            Для кого мероприятие
                        </div>
                        <div className={styles.who_mobile__images}>
                                <div className={styles.who_mobile__image + ' ' + styles.who_mobile__image_1}>
                                    <div className={styles.who_mobile__image_label}>
                                        Блогер
                                    </div>
                                </div>
                                <div className={styles.who_mobile__image + ' ' + styles.who_mobile__image_2}>
                                    <div className={styles.who_mobile__image_label}>
                                        Туроператор
                                    </div>
                                </div>

                                <div className={styles.who_mobile__image + ' ' + styles.who_mobile__image_3}>
                                    <div className={styles.who_mobile__image_label}>
                                        Турагент
                                    </div>
                                </div>

                                <div className={styles.who_mobile__image + ' ' + styles.who_mobile__image_4}>
                                    <div className={styles.who_mobile__image_label}>
                                        Отельер
                                    </div>
                                </div>

                                <div className={styles.who_mobile__image + ' ' + styles.who_mobile__image_5}>
                                    <div className={styles.who_mobile__image_label}>
                                        Вебмастер
                                    </div>
                                </div>

                                <div className={styles.who_mobile__image + ' ' + styles.who_mobile__image_6}>
                                    <div className={styles.who_mobile__image_label}>
                                        Владелец <br/>
                                        тревел-бизнеса
                                    </div>
                                </div>
                        </div>
                    </Wrap>
                </Container>
            </section>
        )
    } else {
        let cl = '';
        if (!appState.whoView) {
            //cl = styles.who__hidden
        }

        const allowTouchMove = (window.innerWidth > 991) ? false : true
        return (
            <section className={styles.who} id="Who">
                <Container>
                    <Wrap>
                        <div className={styles.who__title}>
                            Для кого мероприятие
                        </div>
                        <div className={styles.who__slider + ' who-slider ' + cl}>
                            <div className={styles.who__hole1}>&nbsp;</div>
                            <div className={styles.who__slider_wrap}>
                                <Swiper
                                    spaceBetween={48}
                                    slidesPerView={'auto'}
                                    loop={true}
                                    allowTouchMove={allowTouchMove}
                                    centeredSlides={true}
                                    speed={3000}
                                    autoplay={{
                                        "delay": 1,
                                    }}

                                >
                                    <SwiperSlide>
                                        <div className={styles.who__image + ' ' + styles.who__image_1}>
                                            <div className={styles.who__image_label}>
                                                Блогер
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={styles.who__image + ' ' + styles.who__image_2}>
                                            <div className={styles.who__image_label}>
                                                Туроператор
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={styles.who__image + ' ' + styles.who__image_3}>
                                            <div className={styles.who__image_label}>
                                                Турагент
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={styles.who__image + ' ' + styles.who__image_4}>
                                            <div className={styles.who__image_label}>
                                                Отельер
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={styles.who__image + ' ' + styles.who__image_5}>
                                            <div className={styles.who__image_label}>
                                                Вебмастер
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={styles.who__image + ' ' + styles.who__image_6}>
                                            <div className={styles.who__image_label}>
                                                Владелец <br/>
                                                тревел-бизнеса
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                            <div className={styles.who__hole2}>&nbsp;</div>
                        </div>
                    </Wrap>
                </Container>
            </section>

        )
    }
}