import styles from "./Promo2.module.css";
import Container from "../../ui/container/Container";
import Wrap from "../../ui/wrap/Wrap";
import React, {useContext} from "react";
import {appContext} from "../../context/app/appContext";
import {SRLWrapper} from "simple-react-lightbox";
import pr1 from '../../images/pr1.svg';
import pr2 from '../../images/pr2.svg';

export const Promo2 = () => {
    const {appState, get_config_value} = useContext(appContext)
    const isMobile = window.innerWidth < 992 ? true : false
    let image_1 = get_config_value(appState.options, 'gallery_image_1');
    let image_2 = get_config_value(appState.options, 'gallery_image_2');
    let image_3 = get_config_value(appState.options, 'gallery_image_3');
    let image_4 = get_config_value(appState.options, 'gallery_image_4');
    let image_5 = get_config_value(appState.options, 'gallery_image_5');
    let image_6 = get_config_value(appState.options, 'gallery_image_6');
    let image_7 = get_config_value(appState.options, 'gallery_image_7');
    let image_8 = get_config_value(appState.options, 'gallery_image_8');
    let image_9 = get_config_value(appState.options, 'gallery_image_9');
    let image_10 = get_config_value(appState.options, 'gallery_image_10');

    if (isMobile) {
        return (
            <section className={styles.promo_mobile} id="Promo">
                <Container>
                    <Wrap>
                        <div className={styles.promo_mobile__video}>
                            <iframe width="560" height="315"
                                    src={get_config_value(appState.options, 'youtube_link')}
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen/>
                        </div>
                        <div className={styles.promo_mobile__text}>
                            <div className={styles.promo_mobile__title}>
                                {get_config_value(appState.options, 'text5_1')}
                                <strong>{get_config_value(appState.options, 'text5_2')}</strong>
                                {get_config_value(appState.options, 'text5_3')}
                            </div>
                            <div
                                className={styles.promo_mobile__desc}>{get_config_value(appState.options, 'text3')}</div>
                            <div className={styles.promo_mobile__btns}>
                                <a href={get_config_value(appState.options, 'tg_link')} className={styles.promo_mobile__btn1} target="_blank" rel="noreferrer">
                                    <img src={pr1} alt="" title=""/>
                                    Перейти в Telegram-канал
                                </a>
                                <a href={get_config_value(appState.options, 'materials_link')} className={styles.promo_mobile__btn2} target="_blank" rel="noreferrer">
                                    <img src={pr2} alt="" title=""/>
                                    Материалы саммита
                                </a>
                            </div>
                        </div>
                        <div className={styles.mobile_gallery} id="Gallery">
                            <div className={styles.mobile_gallery__title}>
                                TPAS’2021 в фотографиях
                            </div>
                            <SRLWrapper>
                                <div className={styles.mobile_gallery__cols + ' modulo-lightbox'}>
                                    <div className={styles.mobile_gallery__col}>
                                        <a href={image_1.image[0].thumbnails.full.url}
                                           className={styles.mobile_gallery__image + ' ' + styles.mobile_gallery__image_size_1}>
                                            <img src={image_1.image[0].thumbnails.large.url} alt="" title=""/>
                                        </a>
                                        <a href={image_2.image[0].thumbnails.full.url}
                                           className={styles.mobile_gallery__image + ' ' + styles.mobile_gallery__image_size_2}>
                                            <img src={image_2.image[0].thumbnails.large.url} alt="" title=""/>
                                        </a>
                                        <a href={image_3.image[0].thumbnails.full.url}
                                           className={styles.mobile_gallery__image + ' ' + styles.mobile_gallery__image_size_3}>
                                            <img src={image_3.image[0].thumbnails.large.url} alt="" title=""/>
                                        </a>
                                        <a href={image_4.image[0].thumbnails.full.url}
                                           className={styles.mobile_gallery__image + ' ' + styles.mobile_gallery__image_size_4}>
                                            <img src={image_4.image[0].thumbnails.large.url} alt="" title=""/>
                                        </a>
                                        <a href={image_5.image[0].thumbnails.full.url}
                                           className={styles.mobile_gallery__image + ' ' + styles.mobile_gallery__image_size_5}>
                                            <img src={image_5.image[0].thumbnails.large.url} alt="" title=""/>
                                        </a>
                                    </div>
                                    <div className={styles.mobile_gallery__col}>
                                        <a href={image_6.image[0].thumbnails.full.url}
                                           className={styles.mobile_gallery__image + ' ' + styles.mobile_gallery__image_size_5}>
                                            <img src={image_6.image[0].thumbnails.large.url} alt="" title=""/>
                                        </a>
                                        <a href={image_7.image[0].thumbnails.full.url}
                                           className={styles.mobile_gallery__image + ' ' + styles.mobile_gallery__image_size_4}>
                                            <img src={image_7.image[0].thumbnails.large.url} alt="" title=""/>
                                        </a>
                                        <a href={image_8.image[0].thumbnails.full.url}
                                           className={styles.mobile_gallery__image + ' ' + styles.mobile_gallery__image_size_3}>
                                            <img src={image_8.image[0].thumbnails.large.url} alt="" title=""/>
                                        </a>
                                        <a href={image_9.image[0].thumbnails.full.url}
                                           className={styles.mobile_gallery__image + ' ' + styles.mobile_gallery__image_size_2}>
                                            <img src={image_9.image[0].thumbnails.large.url} alt="" title=""/>
                                        </a>
                                        <a href={image_10.image[0].thumbnails.full.url}
                                           className={styles.mobile_gallery__image + ' ' + styles.mobile_gallery__image_size_1}>
                                            <img src={image_10.image[0].thumbnails.large.url} alt="" title=""/>
                                        </a>
                                    </div>
                                </div>
                            </SRLWrapper>
                        </div>

                    </Wrap>
                </Container>
            </section>
        )
    } else {

        return (
            <section className={styles.promo} id="Promo">
                <Container>
                    <Wrap>
                        <div className={styles.promo__box}>
                            <div className={styles.promo__text}>
                                <div className={styles.promo__title}>
                                    {get_config_value(appState.options, 'text5_1')}
                                    <strong>{get_config_value(appState.options, 'text5_2')}</strong>
                                    {get_config_value(appState.options, 'text5_3')}
                                </div>
                                <div
                                    className={styles.promo__desc}>{get_config_value(appState.options, 'text3')}</div>
                                <div className={styles.promo__btns}>
                                    <a href={get_config_value(appState.options, 'tg_link')} className={styles.promo__btn1} target="_blank" rel="noreferrer">
                                        <img src={pr1} alt="" title=""/>
                                        Перейти в Telegram-канал
                                    </a>
                                    <a href={get_config_value(appState.options, 'materials_link')} className={styles.promo__btn2} target="_blank" rel="noreferrer">
                                        <img src={pr2} alt="" title=""/>
                                        Материалы саммита
                                    </a>
                                </div>
                            </div>
                            <div className={styles.promo__video}>
                                <iframe width="560" height="315"
                                        src={get_config_value(appState.options, 'youtube_link')}
                                        title="YouTube video player" frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen/>
                            </div>
                        </div>
                        <div className={styles.gallery} id="Gallery">

                            <div className={styles.gallery__title}>
                                TPAS’2021 в фотографиях
                            </div>
                            <SRLWrapper>
                                <div className={styles.gallery__cols + ' modulo-lightbox'}>
                                    <div className={styles.gallery__col}>
                                        <a href={image_1.image[0].thumbnails.full.url}
                                           className={styles.gallery__image + ' ' + styles.gallery__image_size_1}>
                                            <img src={image_1.image[0].thumbnails.large.url} alt="" title=""/>
                                        </a>
                                        <a href={image_2.image[0].thumbnails.full.url}
                                           className={styles.gallery__image + ' ' + styles.gallery__image_size_2}>
                                            <img src={image_2.image[0].thumbnails.large.url} alt="" title=""/>
                                        </a>
                                    </div>
                                    <div className={styles.gallery__col}>
                                        <a href={image_3.image[0].thumbnails.full.url}
                                           className={styles.gallery__image + ' ' + styles.gallery__image_size_3}>
                                            <img src={image_3.image[0].thumbnails.large.url} alt="" title=""/>
                                        </a>
                                        <a href={image_4.image[0].thumbnails.full.url}
                                           className={styles.gallery__image + ' ' + styles.gallery__image_size_4}>
                                            <img src={image_4.image[0].thumbnails.large.url} alt="" title=""/>
                                        </a>
                                        <a href={image_5.image[0].thumbnails.full.url}
                                           className={styles.gallery__image + ' ' + styles.gallery__image_size_5}>
                                            <img src={image_5.image[0].thumbnails.large.url} alt="" title=""/>
                                        </a>
                                    </div>
                                    <div className={styles.gallery__col}>
                                        <a href={image_6.image[0].thumbnails.full.url}
                                           className={styles.gallery__image + ' ' + styles.gallery__image_size_1}>
                                            <img src={image_6.image[0].thumbnails.large.url} alt="" title=""/>
                                        </a>
                                        <a href={image_7.image[0].thumbnails.full.url}
                                           className={styles.gallery__image + ' ' + styles.gallery__image_size_2}>
                                            <img src={image_7.image[0].thumbnails.large.url} alt="" title=""/>
                                        </a>
                                    </div>
                                    <div className={styles.gallery__col}>
                                        <a href={image_8.image[0].thumbnails.full.url}
                                           className={styles.gallery__image + ' ' + styles.gallery__image_size_3}>
                                            <img src={image_8.image[0].thumbnails.large.url} alt="" title=""/>
                                        </a>
                                        <a href={image_9.image[0].thumbnails.full.url}
                                           className={styles.gallery__image + ' ' + styles.gallery__image_size_4}>
                                            <img src={image_9.image[0].thumbnails.large.url} alt="" title=""/>
                                        </a>
                                        <a href={image_10.image[0].thumbnails.full.url}
                                           className={styles.gallery__image + ' ' + styles.gallery__image_size_5}>
                                            <img src={image_10.image[0].thumbnails.large.url} alt="" title=""/>
                                        </a>
                                    </div>
                                </div>
                            </SRLWrapper>
                        </div>
                    </Wrap>
                </Container>
            </section>
        )
    }
}