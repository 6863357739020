import styles from "./Footer.module.css";
import Container from "../../ui/container/Container";
import Wrap from "../../ui/wrap/Wrap";
import React, {useContext} from "react";
import appStore from "../../images/appStore.svg";
import googlePlay from "../../images/googlePlay.svg";
import plan from "../../images/plan.png";
import {Link} from 'react-scroll'
import {appContext} from "../../context/app/appContext";

export const Footer = () => {
    const {menu_close} = useContext(appContext)
    let offset = -80
    return (
        <footer className={styles.footer} id="About">
            <Container>
                <Wrap>
                    <div className={styles.footer__top}>
                        <div className={styles.footer__heading}>Travelpayouts</div>
                        <div className={styles.footer__desc}>
                            Крупнейшая платформа партнёрского маркетинга, ориентированная на путешествия. Мы помогаем
                            зарабатывать <br/>
                            на всём спектре туристических услуг, включая авиабилеты, отели, пакетные туры, автобусные и
                            железнодорожные билеты, экскурсии, мероприятия, страхование и многое другое, объединив более
                            90 туристических брендов <br/>
                            и 300 000 партнёров.
                        </div>
                        <div className={styles.footer__items}>
                            <div className={styles.footer__item}>
                                <div className={styles.footer__item_num}>
                                    10 лет
                                </div>
                                <div className={styles.footer__item_desc}>
                                    в мире тревел-услуг <br/>
                                    и монетизации трафика
                                </div>
                            </div>
                            <div className={styles.footer__item}>
                                <div className={styles.footer__item_num}>
                                    2+ млрд ₽
                                </div>
                                <div className={styles.footer__item_desc}>
                                    мы выплатили<br/> с 2011 года
                                </div>
                            </div>
                            <div className={styles.footer__item}>
                                <div className={styles.footer__item_num}>
                                    376 335 477 ₽
                                </div>
                                <div className={styles.footer__item_desc}>
                                    Выплатили аффилиатам <br/>
                                    в 2021 году
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.footer__middle}>
                        <div className={styles.footer__menu}>
                            <nav>
                                <div className={styles.footer__label}>Компания</div>
                                <ul>
                                    <li><a href="https://tp.click/1ZM" target="_blank" rel="noreferrer">О
                                        Travelpayouts</a></li>
                                    <li><a href="https://tp.click/1ZN" target="_blank" rel="noreferrer">Для брендов</a>
                                    </li>
                                    <li><a href="https://tp.click/1Z4" target="_blank" rel="noreferrer">Вакансии</a>
                                    </li>
                                    <li><a href="https://tp.click/1ZO" target="_blank" rel="noreferrer">Release
                                        Notes</a></li>
                                </ul>
                            </nav>
                            <nav>
                                <div className={styles.footer__label}>Вебмастерам</div>
                                <ul>
                                    <li><a href="https://tp.click/1Z2" target="_blank" rel="noreferrer">Блог</a></li>
                                    <li><a href="https://tp.click/1Z3" target="_blank" rel="noreferrer">Мероприятия</a>
                                    </li>
                                    <li><a href="https://tp.click/1Z5" target="_blank" rel="noreferrer">Справочный центр</a>
                                    </li>
                                    <li><a href="https://tp.click/1ZP" target="_blank" rel="noreferrer">Как начать</a>
                                    </li>
                                </ul>
                            </nav>
                            <nav>
                                <div className={styles.footer__label}>Инструменты</div>
                                <ul>
                                    <li><a href="https://tp.click/1ZQ" target="_blank" rel="noreferrer">WordPress
                                        плагин</a></li>
                                    <li><a href="https://tp.click/1ZR" target="_blank" rel="noreferrer">White Label</a>
                                    </li>
                                </ul>
                            </nav>
                            <nav>
                                <div className={styles.footer__label}>Документы</div>
                                <ul>
                                    <li><a href="https://tp.click/1Z6" target="_blank"
                                           rel="noreferrer">Конфиденциальность</a></li>
                                    <li><a href="https://tp.click/1Z7" target="_blank" rel="noreferrer">Правила и
                                        условия</a></li>
                                </ul>
                            </nav>
                        </div>
                        <div className={styles.footer__apps_wrap}>
                            <div className={styles.footer__label}>
                                Приложение для <br/>
                                отслеживания статистики
                            </div>
                            <div className={styles.footer__apps}>
                                <a href="https://tp.click/1Z9" target="_blank" rel="noreferrer">
                                    <img src={appStore} alt="" title=""/>
                                </a>
                                <a href="https://tp.click/1Za" target="_blank" rel="noreferrer">
                                    <img src={googlePlay} alt="" title=""/>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className={styles.footer__bottom}>
                        <div className={styles.footer__copyright}>
                            © Travelpayouts, 2011–2021
                        </div>
                        <div className={styles.footer__plan}>
                            <Link
                                to="Promo"
                                spy={true}
                                smooth={true}
                                duration={500}
                                offset={offset}
                                onClick={() => menu_close()}
                            >
                                <img src={plan} alt="" title=""/>
                            </Link>
                        </div>
                        <div className={styles.footer__soc}>
                            <a href="https://tp.click/1Qs" target="_blank" rel="noreferrer">
                                <svg width="22" height="20" viewBox="0 0 22 20" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M21.3333 1.32834L17.9594 18.5558C17.9594 18.5558 17.4867 19.7509 16.1899 19.178L11.3526 15.4224L11.3884 15.4507L8.30898 18.2952C8.30898 18.2952 8.0679 18.4795 7.80425 18.3644L8.42659 12.9889C8.42659 12.9889 17.0628 5.12712 17.4167 4.79255C17.7706 4.45798 17.6518 4.38637 17.6518 4.38637C17.6756 3.98019 17.0153 4.38637 17.0153 4.38637L5.57161 11.7469L0.806967 10.1223C0.806967 10.1223 0.0754099 9.85931 0.00415427 9.28525C-0.0682889 8.71237 0.830719 8.40128 0.830719 8.40128L19.7752 0.874029C19.7752 0.874029 21.3333 0.181409 21.3333 1.32834Z"/>
                                </svg>
                            </a>
                            <a href="https://tp.click/1Qt" target="_blank" rel="noreferrer">
                                <svg width="12" height="20" viewBox="0 0 12 20" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M7.54321 20V10.8738H10.6059L11.0648 7.31686H7.54321V5.04854C7.54321 4.01589 7.82564 3.31862 9.30843 3.31862H11.1884V0.141218C10.8618 0.0970876 9.74091 0 8.44347 0C5.72503 0 3.87154 1.65931 3.87154 4.6955V7.31686H0.800049V10.8738H3.87154V20H7.54321Z"/>
                                </svg>

                            </a>
                            <a href="https://tp.click/1Qu" target="_blank" rel="noreferrer">
                                <svg width="20" height="18" viewBox="0 0 20 18" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M6.2896 17.127C13.8368 17.127 17.9648 10.8742 17.9648 5.45182C17.9648 5.27422 17.9648 5.09742 17.9528 4.92142C18.7559 4.34055 19.4491 3.62132 20 2.79742C19.2511 3.12926 18.4567 3.34688 17.6432 3.44302C18.4998 2.9302 19.1409 2.12363 19.4472 1.17342C18.6417 1.65138 17.7605 1.98823 16.8416 2.16942C16.2229 1.51156 15.4047 1.07595 14.5135 0.929987C13.6223 0.784023 12.7079 0.935846 11.9116 1.36196C11.1154 1.78808 10.4819 2.46473 10.109 3.28721C9.73605 4.10969 9.64462 5.03214 9.8488 5.91182C8.2174 5.83003 6.62144 5.40608 5.16451 4.66749C3.70759 3.9289 2.42227 2.89217 1.392 1.62462C0.867274 2.52795 0.70656 3.59731 0.942583 4.61497C1.17861 5.63263 1.79362 6.52208 2.6624 7.10222C2.00939 7.08286 1.37062 6.90671 0.8 6.58862C0.8 6.60542 0.8 6.62302 0.8 6.64062C0.800259 7.58799 1.12821 8.50611 1.72823 9.23925C2.32824 9.9724 3.16338 10.4754 4.092 10.663C3.4879 10.8278 2.85406 10.8519 2.2392 10.7334C2.50141 11.5488 3.01189 12.2617 3.69926 12.7727C4.38662 13.2836 5.21649 13.5669 6.0728 13.583C4.61979 14.725 2.82485 15.3449 0.9768 15.343C0.650323 15.3424 0.324163 15.3226 0 15.2838C1.87651 16.488 4.05993 17.1268 6.2896 17.1238V17.127Z"/>
                                </svg>

                            </a>
                            <a href="https://tp.click/1Qv" target="_blank" rel="noreferrer">
                                <svg width="20" height="16" viewBox="0 0 20 16" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M17.8068 1.38037C18.6705 1.61914 19.3523 2.30078 19.5795 3.16455C20 4.73291 20 8.00537 20 8.00537C20 8.00537 20 11.2783 19.5909 12.8462C19.3523 13.7212 18.6818 14.4033 17.8182 14.6304C16.25 15.0508 10 15.0508 10 15.0508C10 15.0508 3.75 15.0508 2.19318 14.6304C1.32953 14.3916 0.647705 13.71 0.420471 12.8462C0 11.2783 0 8.00537 0 8.00537C0 8.00537 0 4.73291 0.409119 3.16455C0.647705 2.28955 1.31818 1.60791 2.18182 1.38037C3.75 0.959961 10 0.959961 10 0.959961C10 0.959961 16.25 0.959961 17.8068 1.38037ZM7.95459 5.02832V10.9829L13.1819 8.00537L7.95459 5.02832Z"/>
                                </svg>
                            </a>
                            <a href="https://tp.click/1Qw" target="_blank" rel="noreferrer">
                                <svg width="20" height="12" viewBox="0 0 20 12" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M19.5395 0.857271C19.6802 0.388322 19.5395 0.0600586 18.883 0.0600586H16.6946C16.1318 0.0600586 15.8817 0.357059 15.741 0.685323C15.741 0.685323 14.6312 3.40522 13.0524 5.15596C12.5366 5.67181 12.3177 5.82812 12.0363 5.82812C11.8957 5.82812 11.6924 5.67181 11.6924 5.20286V0.857271C11.6924 0.294533 11.5361 0.0600586 11.0672 0.0600586H7.62823C7.28433 0.0600586 7.06549 0.325796 7.06549 0.56027C7.06549 1.09175 7.84707 1.2168 7.94086 2.68617V5.90628C7.94086 6.6097 7.81581 6.73476 7.53444 6.73476C6.79975 6.73476 4.98648 4.01486 3.92353 0.888534C3.70469 0.310164 3.50148 0.0600586 2.93874 0.0600586H0.750317C0.125053 0.0600586 0 0.357059 0 0.685323C0 1.26369 0.734686 4.13991 3.45459 7.93839C5.26785 10.5332 7.81581 11.9401 10.1293 11.9401C11.5205 11.9401 11.6924 11.6275 11.6924 11.096V9.12639C11.6924 8.50113 11.8175 8.37608 12.2708 8.37608C12.5991 8.37608 13.1462 8.53239 14.4436 9.78292C15.9286 11.2679 16.1787 11.9401 17.0072 11.9401H19.1956C19.8209 11.9401 20.1335 11.6275 19.9459 11.0178C19.7427 10.4082 19.0393 9.51718 18.1014 8.45423C17.5856 7.8446 16.8196 7.2037 16.6008 6.87544C16.2725 6.45339 16.3663 6.26581 16.6008 5.90628C16.6008 5.89065 19.2738 2.13906 19.5395 0.857271Z"/>
                                </svg>
                            </a>
                            <a href="https://tp.click/1Qx" target="_blank" rel="noreferrer">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M5.31712 2.86437C5.31712 4.1243 4.29174 5.03764 3.03983 5.03764C1.79153 5.03764 0.789062 4.11851 0.789062 2.86437C0.789062 1.60451 1.81478 0.692139 3.06647 0.692139C4.31457 0.692139 5.31712 1.6101 5.31712 2.86437Z"/>
                                    <path d="M5.05236 6.75481V19.3308H1.02705V6.75481H5.05236Z"/>
                                    <path
                                        d="M6.92164 19.3308H10.9469V12.3076C10.9469 11.9318 10.9731 11.5563 11.0795 11.2876C11.3704 10.537 12.0326 9.75917 13.1447 9.75917C14.6014 9.75917 15.1841 10.9128 15.1841 12.6024V19.3308H19.2091V12.1197C19.2091 8.25681 17.223 6.45933 14.5746 6.45933C12.4395 6.45933 11.482 7.67808 10.947 8.53503V6.75481H6.92164C6.9745 7.9349 6.92164 19.3308 6.92164 19.3308Z"/>
                                </svg>
                            </a>
                        </div>
                    </div>
                </Wrap>
            </Container>
        </footer>
    )
}