import styles from "./Header.module.css";
import Container from "../../ui/container/Container";
import Wrap from "../../ui/wrap/Wrap";
import React, {useContext} from "react";
import {appContext} from "../../context/app/appContext";
import {Link} from 'react-scroll'
import logo from "../../images/logo.svg";
import logo2 from "../../images/logo2.svg";
import mlogo from "../../images/m-logo.svg";
import mlogo2 from "../../images/m-logo2.svg";

export const Header = () => {
    const {appState, header_view, menu_open, menu_close,send_tp_reg} = useContext(appContext)
    const isMobile = window.innerWidth < 992 ? true : false
    const handleScroll = () => {
        if (appState.headerView) {
            if (window.scrollY <= 40) {
                header_view(false)
            }
        } else {
            if (window.scrollY > 40) {
                header_view(true)
            }
        }
    };

    React.useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    });

    if (isMobile) {
        let headerCLass = ''
        if (appState.headerView || appState.menuOpen) {
            headerCLass = styles.header_white
        }
        let offset = -80
        return (
            <React.Fragment>
                <header className={styles.header_mobile + ' ' + headerCLass}>
                    <Container>
                        <Wrap>
                            <div className={styles.header_mobile__flex}>
                                {
                                    !appState.headerView && (
                                        <div className={styles.header_mobile__logo}>
                                            <img src={mlogo} alt="" title=""/>
                                        </div>
                                    )
                                }
                                {
                                    appState.headerView && (
                                        <div className={styles.header_mobile__logo}>
                                            <img src={mlogo2} alt="" title=""/>
                                        </div>
                                    )
                                }

                                {
                                    !appState.menuOpen && (
                                        <div className={styles.header_mobile__burger} onClick={() => menu_open()}>
                                            <svg width="25" height="19" viewBox="0 0 25 19" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 1H24M1 9.5H24M1 18H24" stroke="black" strokeWidth="1.6"
                                                      strokeLinecap="round"/>
                                            </svg>
                                        </div>
                                    )
                                }
                                {
                                    appState.menuOpen && (
                                        <div className={styles.header_mobile__burger} onClick={() => menu_close()}>
                                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 1L18 18M18 1L1 18" stroke="black" strokeWidth="1.6"
                                                      strokeLinecap="round"/>
                                            </svg>
                                        </div>
                                    )
                                }
                            </div>
                        </Wrap>
                    </Container>
                </header>
                {
                    appState.menuOpen && (
                        <div className={styles.mobile_menu}>
                            <Container>
                                <Wrap>

                                    <nav className={styles.mobile_menu__menu}>
                                        <ul>
                                            {!appState.popupThank && (
                                                <li>
                                                    <Link activeClass={styles.header__menu_item_active}
                                                          className={styles.header__menu_item} to="Who"
                                                          spy={true}
                                                          smooth={true}
                                                          duration={500}
                                                          offset={offset}>
                                                        Для кого?
                                                    </Link>
                                                </li>
                                            )
                                            }
                                            <li>
                                                <Link activeClass={styles.mobile_menu__menu_menu_item_active}
                                                      className={styles.mobile_menu__menu_menu_item} to="Calendar"
                                                      spy={true}
                                                      smooth={true}
                                                      duration={500}
                                                      offset={offset}
                                                      onClick={() => menu_close()}
                                                >
                                                    Программа
                                                </Link>
                                            </li>
                                            <li>
                                                <Link activeClass={styles.mobile_menu__menu_menu_item_active}
                                                      className={styles.mobile_menu__menu_menu_item} to="Partners"
                                                      spy={true}
                                                      smooth={true}
                                                      duration={500}
                                                      offset={offset}
                                                      onClick={() => menu_close()}
                                                >
                                                    Партнёры
                                                </Link>
                                            </li>
                                            <li>
                                                <Link activeClass={styles.mobile_menu__menu_menu_item_active}
                                                      className={styles.mobile_menu__menu_menu_item} to="About"
                                                      spy={true}
                                                      smooth={true}
                                                      duration={500}
                                                      offset={offset}
                                                      onClick={() => menu_close()}
                                                >
                                                    О Travelpayouts
                                                </Link>
                                            </li>
                                        </ul>
                                    </nav>
                                    <a className={styles.mobile_menu__btn} onClick={() => send_tp_reg()} href="https://www.travelpayouts.com/ru/"
                                       target="_blank" rel="noreferrer">
                                        Регистрация в Travelpayouts
                                    </a>
                                </Wrap>
                            </Container>
                        </div>
                    )
                }
            </React.Fragment>
        )
    } else {
        let headerCLass = ''
        if (appState.headerView) {
            headerCLass = styles.header_white
        }
        let offset = -96
        return (
            <header className={styles.header + ' ' + headerCLass}>
                <Container>
                    <Wrap>
                        <div className={styles.header__flex}>
                            {
                                !appState.headerView && (
                                    <div className={styles.header__logo}>
                                        <img src={logo} alt="" title=""/>
                                    </div>
                                )
                            }
                            {
                                appState.headerView && (
                                    <div className={styles.header__logo}>
                                        <img src={logo2} alt="" title=""/>
                                    </div>
                                )
                            }
                            <nav className={styles.header__menu}>
                                <ul>
                                    {!appState.popupThank && (
                                        <li>
                                            <Link activeClass={styles.header__menu_item_active}
                                                  className={styles.header__menu_item} to="Who"
                                                  spy={true}
                                                  smooth={true}
                                                  duration={500}
                                                  offset={offset}>
                                                Для кого?
                                            </Link>
                                        </li>
                                    )
                                    }
                                    <li>
                                        <Link activeClass={styles.header__menu_item_active}
                                              className={styles.header__menu_item} to="Calendar"
                                              spy={true}
                                              smooth={true}
                                              duration={500}
                                              offset={offset}>
                                            Программа
                                        </Link>
                                    </li>
                                    <li>
                                        <Link activeClass={styles.header__menu_item_active}
                                              className={styles.header__menu_item} to="Partners"
                                              spy={true}
                                              smooth={true}
                                              duration={500}
                                              offset={offset}>
                                            Партнёры
                                        </Link>
                                    </li>
                                    <li>
                                        <Link activeClass={styles.header__menu_item_active}
                                              className={styles.header__menu_item} to="About"
                                              spy={true}
                                              smooth={true}
                                              duration={500}
                                              offset={offset}>
                                            О Travelpayouts
                                        </Link>
                                    </li>
                                </ul>
                            </nav>

                            <a className={styles.header__btn} onClick={() => send_tp_reg()}
                               href="https://www.travelpayouts.com/ru/" target="_blank"
                               rel="noreferrer">
                                Регистрация в Travelpayouts
                            </a>
                        </div>
                    </Wrap>
                </Container>
            </header>
        )
    }
}