import styles from "./Speakers.module.css";
import Container from "../../ui/container/Container";
import Wrap from "../../ui/wrap/Wrap";
import React, {useContext} from "react";
import {appContext} from "../../context/app/appContext";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/swiper.min.css";
import SwiperCore, {
    Autoplay, Pagination, Navigation
} from 'swiper/core';

SwiperCore.use([Autoplay, Pagination, Navigation]);


export const Speakers = () => {
    const {appState} = useContext(appContext)
    const isMobile = window.innerWidth < 992 ? true : false

    if (appState.speakers.length > 0) {
        let arr = [];
        let count = 10;
        for (let i = 1; i <= Math.ceil(appState.speakers.length / count); i++) {
            arr.push(i);
        }
        if (isMobile) {
            return (
                <section className={styles.speakers}>
                    <Container>
                        <Wrap>
                            <div className={styles.speakers__title}>
                                Спикеры
                            </div>
                            <div className={styles.speakers__items}>
                                {
                                    appState.speakers.map((d, index) => {
                                        let key = 'speakers' + index
                                        return (
                                            <div className={styles.speakers__item}
                                                 key={key}>
                                                <div
                                                    className={styles.speakers__item_image}>
                                                    <img src={d.photo[0].url} alt=""
                                                         title="" loading="lazy"/>
                                                </div>
                                                <div className={styles.speakers__item_text}>
                                                    <div
                                                        className={styles.speakers__item_company}>
                                                        {d.company}
                                                    </div>
                                                    <div className={styles.speakers__item_name}>
                                                        {d.name}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Wrap>
                    </Container>
                </section>
            )
        } else {
            return (
                <section className={styles.speakers}>
                    <Container>
                        <Wrap>
                            <div className={styles.speakers__title}>
                                Спикеры
                            </div>
                            <div className={styles.speakers__slider}>
                                <div className={styles.speakers__prev} id="speakers-prev">
                                    <svg width="21" height="44" viewBox="0 0 21 44" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19 2L3 22L19 42" stroke="#E7ECFE" strokeWidth="4"/>
                                    </svg>
                                </div>
                                <Swiper
                                    spaceBetween={0}
                                    slidesPerView={1}
                                    loop={true}
                                    navigation={{
                                        'prevEl': '#speakers-prev',
                                        'nextEl': '#speakers-next',
                                    }}
                                >
                                    {
                                        arr.map((i, index2) => {
                                            let key = 'speakersSlide' + index2
                                            return (
                                                <SwiperSlide key={key}>
                                                    <div className={styles.speakers__items}>
                                                        {
                                                            appState.speakers.map((d, index) => {
                                                                let key = 'speakers' + index
                                                                let j = index + 1
                                                                if (j > count * (i - 1) && j <= count * i) {
                                                                    return (
                                                                        <div className={styles.speakers__item}
                                                                             key={key}>
                                                                            <div
                                                                                className={styles.speakers__item_image}>
                                                                                <img src={d.photo[0].url} alt=""
                                                                                     title="" loading="lazy"/>
                                                                            </div>
                                                                            <div className={styles.speakers__item_text}>
                                                                                <div
                                                                                    className={styles.speakers__item_company}>
                                                                                    {d.company}
                                                                                </div>
                                                                                <div
                                                                                    className={styles.speakers__item_name}>
                                                                                    {d.name}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                } else {
                                                                    return null
                                                                }
                                                            })
                                                        }
                                                    </div>
                                                </SwiperSlide>)
                                        })
                                    }
                                </Swiper>
                                <div className={styles.speakers__next} id="speakers-next">
                                    <svg width="21" height="44" viewBox="0 0 21 44" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2 2L18 22L2 42" stroke="#E7ECFE" strokeWidth="4"/>
                                    </svg>
                                </div>
                            </div>
                        </Wrap>
                    </Container>
                </section>
            )
        }
    } else {
        return null
    }
}