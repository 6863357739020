export const GET_USER = 'GET_USER'
export const TEST_USER = 'TEST_USER'
export const GET_USER2 = 'GET_USER2'
export const APP_READY = 'APP_READY'
export const HEADER_VIEW = 'HEADER_VIEW'
export const PROMO_VIEW = 'PROMO_VIEW'
export const WHO_VIEW = 'WHO_VIEW'
export const POPUP_SHOW = 'POPUP_SHOW'
export const POPUP_HIDE = 'POPUP_HIDE'
export const SELECT_OPEN = 'SELECT_OPEN'
export const SELECT_OPTION = 'SELECT_OPTION'
export const SET_NAME = 'SET_NAME'
export const SET_EMAIL = 'SET_EMAIL'
export const FORM_SEND = 'FORM_SEND'
export const MENU_OPEN = 'MENU_OPEN'
export const MENU_CLOSE = 'MENU_CLOSE'
export const ANIMATION = 'ANIMATION'
export const OPEN_CALENDAR = 'OPEN_CALENDAR'